<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> قائمة سجلات المنتجات </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <br>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>

                                                        <th> رقم السجل  </th>

                                                        <th> محتوى السجل </th>

                                                         <th> إجراء </th>

                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr v-for="(rec, index) in records" :key="rec.id">

                                                        <td> {{ rec.id }} </td>

                                                        <td> 
                                                            <button class="btn btn-primary">
                                                                <router-link :to ="{ name: 'record-detile',params:{id:rec.id} }">  
                                                                     <span class="sr-only btn-text">  منتجات السجل 
                                                                    </span>
                                                                 </router-link>
                                                            </button>
                                                        </td>

                                                        <td>
                                                            <button class="btn btn-primary">
                                                                <router-link :to="'update-record/' + rec.id">

                                                                    <span class="sr-only btn-text"> تعديل
                                                                    </span>

                                                                </router-link>
                                                            </button>

                                                            <button type="button"
																class="btn btn-danger" v-on:click="deleteRecord(rec.id)">
																<span class="sr-only"> حذف
																</span>
															</button>

                                                        </td>
                                                        
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div class="paginate">
                                            <v-pagination
                                                v-model="page"
                                                :pages="pageCount"
                                                :range-size="1"
                                                active-color="blue"
                                                @update:modelValue="updateHandler"
                                            />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'product-code-menu',

    data() {
        return {
            records: [],
            page:1,
            pageCount:1,
            records_length:1,
            categories:[],
          
        }
    },

    components: {
        VPagination
  },

    mounted() {

        this.get_count_records();
        this.get_records(1);
       this.get_categories();

    },

    methods: {


        get_categories() {

           axios.get("categories",
              {
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  }
              })
              .then((response) => {
                  this.categories = response.data.data;

              })
              .catch((error) => {
                  console.log(error)
              })
        },

        get_count_records() {        

             axios.get("records_count",
                 {
             headers: {
                         Authorization: 'Bearer ' + localStorage.getItem('token'),        

                     }
                 })
                 .then((response) => {

                     this.records_length = response.data.data;

                     console.log('this.records_length');
                     console.log(this.records_length);

                 })
                 .catch((error) => {
                   console.log(error)
                 })

         },


        updateHandler(){
       
       this.get_records(this.page);
       window.scrollTo(0, 0);
       },

       get_records(page){
       
            axios.get("records_paginate/"+page,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{

       this.records=response.data.data;
       
       console.log('this.records');
       console.log(this.records);

   
       console.log('this.records_length');
       console.log(this.records_length);

       if(this.records_length%100 != 0)
       {
          this.pageCount = parseInt(this.records_length/100) + 1;
          console.log('this.pageCount');
          console.log(this.pageCount);
       }
       else
       {
          this.pageCount = parseInt(this.records_length/100);
          console.log('this.pageCount');
          console.log(this.pageCount);
       }

       
       })
       .catch((error)=>{
       console.log(error)
       })
    },
     
     deleteRecord(id) {

            axios.delete("record/" + id,
               {
                   headers: {
                       Authorization: 'Bearer ' + localStorage.getItem('token')
                   }
               })
               .then((response) => {
                   
                   console.log(response);
                   this.get_records(1);
               })
               .catch((error) => {
                   console.log(error)
               })
    },

    }

};


</script>

<style>
.table tbody tr:last-child td,
.table tbody tr:last-child th {
    border: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0;

}


.dropdown-prod-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;

}

.drop-down-icon {
    width: 7px !important;
    height: 7px !important;
    margin-right: 10px;
}


.dropdown,
.dropdown_prod {
    width: 100px;
    padding: 10%;
    text-align: center;
    border-radius: 4px;


}

.pending {
    background-color: rgb(209, 60, 60);

}

.pending:hover {
    background-color: rgb(207, 107, 107);

}

.accepted {
    background-color: rgb(235, 161, 26);

}

.accepted:hover {
    background-color: rgb(223, 176, 88);

}

.completed {
    background-color: rgb(86, 170, 47);

}

.completed:hover {
    background-color: rgb(84, 150, 54);

}

.menu-choice:hover {
    background-color: rgb(236, 231, 231);

}

.menu-choice {
    padding: 5%
}

.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.paginate{
    text-align: center !important;
     width:70%;
    direction: ltr;
}

.paginate button{
    border:1px solid blue;
    padding: 20px;
}  
</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1>  الاستعلام عن طقم </h1>
                                </div>
                            </div>
                        </div>

                        <div class="row">
						     <div class="col-md-4">

							     <h5> رمز الطقم</h5>

                                 <input class="form-control" type="text" name="package_code" v-model="package_code" placeholder="ادخل رمز الطقم">

							     
							     <!-- <select class="form-select  prod-inpu prdId"
                                    v-model="pack_id"
							     	aria-label="Default select example">
							     	<option v-for="pack in packages"
								     	v-bind:value="pack.id">{{pack.name }}</option>
							     </select> -->

						     </div>

                              <div class="col-md-5">
								 <div v-on:click="get_package_products()"
								 	class="btn btn-primary"> استعلام
								 </div>
							  </div>
                        </div>

                         <br>

                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <!-- <h4>بحث في المنتجات</h4>
                                            <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                            <br> -->
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th> صورة المنتج </th>
                                                        <th> اسم المنتج </th>
                                                        <th>رمز المنتج </th>
                                                        <th> عدد القطع </th>
                                                        <th> إجراء </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr  v-for=" (product ,index) in products">
                                                       
                                                        <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image">
                                                       
                                                        <td>  {{product.product.name}} </td>

                                                        <td> {{product.product.code}} </td>

                                                        <td> {{product.qty}} </td>

                                                        <td>
                                                            <button class="btn btn-primary"  v-on:click="download_image(product.product.image)">تحميل الصورة  </button>
                                                        </td>
                                                   
                                                    </tr>
                                                </tbody>
                                            </table>
                                                                                           
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- End Content Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
      import { useRouter,useRoute } from 'vue-router'
      import axios from 'axios';

export default {
    name: 'query-package',
    data() {
    return {
    pack_id:'',
    products:[],
    packages:[],
    package_code:'',

    }
}, 

methods:{

    get_package_products(){
       
       axios.get("package/package_by_code/"+this.package_code,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
        
       this.products=response.data.data.products;
       console.log('this.products');
       console.log(this.products);

       })
       .catch((error)=>{
       console.log(error)
       })
    },

    download_image(img) {

        this.img_url = img;

        axios({
                  method: 'GET',
                  url: 'https://lh4.googleusercontent.com/d/' + this.img_url,
                  responseType: 'blob',  
              }).then((response) => {
                     const url = window.URL.createObjectURL(new Blob([response.data]));
                     const link = document.createElement('a');
                     link.href = url;
                     link.setAttribute('download', 'image.jpg');
                     document.body.appendChild(link);
                     link.click();
        });

    },


},
 
};

    $(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});

   
</script>

<style>


.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.product-image{
    width:150px;
    height: 150px;
}
</style>
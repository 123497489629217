<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1>لوحة التحكم</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="card widget widget-stats">
                                    <div class="card-body">
                                        <div class="widget-stats-container d-flex">
                                            <div class="widget-stats-icon widget-stats-icon-primary">
                                                <i class="material-icons-outlined">paid</i>
                                            </div>
                                            <div class="widget-stats-content flex-fill">
                                                <span class="widget-stats-title"> التصنيفات </span>
                                                <span class="widget-stats-amount">{{ count_category }}</span>
                                                <span class="widget-stats-info">471 Orders Total</span>
                                            </div>
                                            <div
                                                class="widget-stats-indicator widget-stats-indicator-negative align-self-start">
                                                <i class="material-icons">keyboard_arrow_down</i> 4%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card widget widget-stats">
                                    <div class="card-body">
                                        <div class="widget-stats-container d-flex">
                                            <div class="widget-stats-icon widget-stats-icon-warning">
                                                <i class="material-icons-outlined">person</i>
                                            </div>
                                            <div class="widget-stats-content flex-fill">
                                                <span class="widget-stats-title"> المنتجات </span>
                                                <span class="widget-stats-amount">{{ count_product }}</span>
                                                <span class="widget-stats-info">790 unique this month</span>
                                            </div>
                                            <div
                                                class="widget-stats-indicator widget-stats-indicator-positive align-self-start">
                                                <i class="material-icons">keyboard_arrow_up</i> 12%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card widget widget-stats">
                                    <div class="card-body">
                                        <div class="widget-stats-container d-flex">
                                            <div class="widget-stats-icon widget-stats-icon-danger">
                                                <i class="material-icons-outlined">file_download</i>
                                            </div>
                                            <div class="widget-stats-content flex-fill">
                                                <span class="widget-stats-title"> جميع الطلبات </span>
                                                <span class="widget-stats-amount">{{ count_order }}</span>
                                                <span class="widget-stats-info">87 items downloaded</span>
                                            </div>
                                            <div
                                                class="widget-stats-indicator widget-stats-indicator-positive align-self-start">
                                                <i class="material-icons">keyboard_arrow_up</i> 7%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card widget widget-stats">
                                    <div class="card-body">
                                        <div class="widget-stats-container d-flex">
                                            <div class="widget-stats-icon widget-stats-icon-danger">
                                                <i class="material-icons-outlined">file_download</i>
                                            </div>
                                            <div class="widget-stats-content flex-fill">
                                                <span class="widget-stats-title"> طلبات قيد التنفيذ </span>
                                                <span class="widget-stats-amount">{{ count_pendding_order }}</span>
                                                <span class="widget-stats-info">87 items downloaded</span>
                                            </div>
                                            <div
                                                class="widget-stats-indicator widget-stats-indicator-positive align-self-start">
                                                <i class="material-icons">keyboard_arrow_up</i> 7%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card widget widget-stats">
                                    <div class="card-body">
                                        <div class="widget-stats-container d-flex">
                                            <div class="widget-stats-icon widget-stats-icon-danger">
                                                <i class="material-icons-outlined">file_download</i>
                                            </div>
                                            <div class="widget-stats-content flex-fill">
                                                <span class="widget-stats-title"> الطلبات الجاهزة </span>
                                                <span class="widget-stats-amount">{{ count_complete_order }}</span>
                                                <span class="widget-stats-info">87 items downloaded</span>
                                            </div>
                                            <div
                                                class="widget-stats-indicator widget-stats-indicator-positive align-self-start">
                                                <i class="material-icons">keyboard_arrow_up</i> 7%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
    name: 'Home',
    data() {
        return {
            isAuth: false,
            username: '',
            count_category: '',
            count_product: '',
            count_order: '',
            count_pendding_order: '',
            count_complete_order: '',

        }
    },
    methods: {
        isLoogedin() {
            const router = useRouter();

            let token = localStorage.getItem('token');
            if (token != null) {
                this.isAuth = true;
                this.username = localStorage.getItem('username');
            }
            else
                router.push('/signin');
        },

        get_count_categories() {

            axios.get("categories_count",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.count_category = response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })

        },

        get_count_products() {

            axios.get("products_count",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.count_product = response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })

        },

        get_count_orders() {

            axios.get("orders_count",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.count_order = response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })

        },

        get_count_pendding_orders() {

            axios.get("pendding_orders_count",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.count_pendding_order = response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })

        },

        get_count_complete_orders() {

            axios.get("complete_orders_count",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.count_complete_order = response.data.data;
                })
                .catch((error) => {
                    console.log(error)
                })

        },


    },
    mounted() {

        this.isLoogedin();

        this.get_count_categories();
        this.get_count_products();
        this.get_count_orders();
        this.get_count_pendding_orders();
        this.get_count_complete_orders();


        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
    }
}
</script>

<style></style>


<template>
    <div class="app-container">
        <div class="app-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="page-description">
                                <h1> ادارة التصنيفات</h1>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-description"></p>
                                    <div class="example-container">
                                        <div class="example-content">
                                            <form action="#" @submit.prevent="submit">
                                                <div class="col-sm-6" id="response">

                                                </div>
                                                <div class="row  mb-12">

                                                    <label for="inputEmail3" class="col-sm-2 col-form-label">
                                                        <h3> اضافة تصنيفات </h3>
                                                    </label>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <label>اسم التصنيف</label>
                                                            <input type="text" class="form-control" v-model="cate_name" name="cate_name"
                                                                id="inputCatgory">
                                                        </div>

                                                        <div class="col-sm-2">
                                                            <label>نوع التصنيف </label>
                                                            <select name="category_type" v-model="category_type" v-on:change="change_cagtegory_type()" class="form-control" >
                                                                <option v-for="category_type in category_types "
                                                                    v-bind:value="category_type.id">{{ category_type.name }}</option>
                                                            </select>
                                                        </div>

                                                        <div class="col-sm-2" id="size_field">
                                                            <label>المساحة بالمتر </label>
                                                            <select name="size" v-model="cate_size" class="form-control">
                                                                <option v-for="size_option in size_options "
                                                                    v-bind:value="size_option">{{ size_option }}</option>
                                                            </select>
                                                        </div>


                                                        <div class="col-sm-4">
                                                            <label> </label>
                                                            <button type="submit" class="btn btn-primary" v-on:click="add_category()"> اضافة تصنيف جديد
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title"></h5>
                                </div>
                                <div class="card-body">

                                    <div class="example-container">
                                        <div class="example-content">
                                            <div class="table-responsive">



                                                <table id="responsive-data-table" class="table" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th> رقم التصنيف </th>
                                                            <th> التصنيف </th>
                                                            <th> المساحة </th>
                                                            <th> اجراء </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="category in categories">
                                                            <td> {{ category.id }} </td>
                                                            <td> {{ category.name }} </td>
                                                            <td> {{ category.size }} </td>

                                                            <!-- <td>20</td> -->
                                                            <!-- <td>ملاحظة</td> -->

                                                            <td>
                                                                <!-- <div class="btn-group mb-1">
                                                                                            <router-link :to ="'update-category/'+category.id">
                                                                                                
																								<span class="sr-only"> تعديل
																								</span>
																							
                                                                                             </router-link>

																							
																						</div>
 -->

                                                                <button class="btn btn-primary">
                                                                    <router-link :to="'update-category/' + category.id">

                                                                        <span class="sr-only btn-text"> تعديل
                                                                        </span>

                                                                    </router-link>
                                                                </button>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
    name: 'catgory',
    data() {
        return {
            categories: [],
            size_options: [],
            category_types:[],
            category_type:1,
            // category_type_id:'',
            cate_name:'',
            cate_size:''
        }
    },

    mounted() {
        this.get_categories();
        this.set_options();
        this.get_categories_types();
    },



    // setup() {
    //     const router = useRouter();
    //     const submit = async e => {
    //         e.preventDefault()
    //         const form = new FormData(e.target);
    //         const inputs = Object.fromEntries(form.entries());
    //         const response = await axios.post('categories', inputs, {
    //             headers: {
    //                 Authorization: 'Bearer ' + localStorage.getItem('token')
    //             }
    //         }
    //         ).then((response) => {
    //             console.log(response);
    //             $('#response').addClass("alert alert-success");
    //             document.getElementById("response").innerHTML = response.data.message;
    //             document.getElementById("response").innerHTML = "تمت إضافة التصنيف  بنجاح";
    //             this.reload();
    //         })

    //             .catch((error) => {

    //             });

    //     }

    //     return {
    //         submit
    //     }
    // },
    methods: {

        set_options() {
            for (let i = 1; i < 1000; i++) {
                let j = i * 0.1;
                this.size_options.push(j.toFixed(1));
            }
        },
        get_categories() {

            axios.get("categories",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.categories = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })
        },


        get_categories_types() {

            axios.get("category_types",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.category_types = response.data.data;
                    console.log('this.category_types');
                    console.log(this.category_types);

                })
                .catch((error) => {
                    console.log(error)
                })
        },

        change_cagtegory_type(){
            if(this.category_type==2)
            {
                document.getElementById('size_field').style.display='none'
                this.cate_size="";
            }
            else{
                document.getElementById('size_field').style.display='block'
            }

        },


        add_category(){
            console.log(this.category_type),
            console.log(this.cate_name),
            console.log(this.cate_size),

          axios.post('categories',{

                name:this.cate_name,               
                category_type_id:this.category_type,
                size:this.cate_size,
            },
          

            {
             headers: {
                 Authorization: 'Bearer ' + localStorage.getItem('token')
            }
            },

                 this.cate_name="",
                 this.category_type_id="",
                 this.cate_size="",


                ).then((response) => {
            console.log(response);
            $('#response').addClass("alert alert-success");
            document.getElementById("response").innerHTML = response.data.message;
            document.getElementById("response").innerHTML = "تمت إضافة تصنيف  بنجاح";
            this.get_categories();
           })

              .catch((error) => {
                console.log(error)
         });




},

    }
};

</script>

<style>
.btn {
    margin-top: 9%;
}
</style>
<template>
	<div class="app align-content-stretch d-flex flex-wrap">
		<div class="app-container">
			<div class="app-content">
				<div class="content-wrapper">
					<div class="container">
						<div class="row">
							<div class="col">
								<div class="page-description">
									<h1> اضافة طلبات </h1>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="ec-content-wrapper">
								<div class="content">
									<div class="row">
										<div class="col-12">
											<div class="card card-default">
												<div class="card-body">
													<div class="row ec-vendor-uploads">
														<div class="col-lg-12">
															<div class="ec-vendor-upload-detail">
															<div class="row">
																<div id="response1">

																</div>
															</div>
																<form class="row g-3">

																	<div id="productsDiv">
																		<div class="row">


																			<div class="col-md-3">
																				
																				<label class="form-label"> الصنف
																				</label>
																				<select class="form-select  prod-inpu prdId"  v-model="selected_category" v-on:change="getProductsOption()" aria-label="Default select example">
                                                 									   <option v-for="category in categories" v-bind:value="category">{{ category.name }}</option>
                                                    
                                                								  </select>
																				
									
																			</div>

																			<div class="col-md-3">
																				
																				<label class="form-label"> رمز المنتج
																				</label>
																				<!-- <select class="form-select product_id prod-inpu prdId" name="product_id" aria-label="Default select example">
                                                 									   <option v-for="prd in productsOptions" v-bind:value="prd.id">{{ prd.code }}</option>
                                                    
                                                								  </select> -->
																				
												
																			 
																				<v-select class="product_id" v-model="selected_prd" name="product_id" :options="productsOptions" :value=id label=code></v-select>
																			
																		</div>
																			<div class="col-md-2">
																				<label class="form-label"> العدد
																					المطلوب</label>
																				<input v-model="prdCount" type="text"
																					class="form-control slug-title prdCount">
																			</div>

																			<div class="col-md-2" id="height_field">
																				<label class="form-label"> الطول:
																					</label>
																				<input v-model="prdHight" type="text"
																					class="form-control slug-title prdHight">

																			</div>

																			<div class="col-md-2" id="width_field">
																				<label class="form-label"> العرض:
																					</label>
																				<input v-model="prdWidth" type="text"
																					class="form-control slug-title prdWidth">

																			</div>



																		</div>
																		<div class="col-md-12 text-center">
																		<p>  <br> يمكنك اضافة اكثر من منتج في نفس الوقت وحفظهم مرة واحدة <br></p>
																		<div class="btn btn-primary button btn-lg btn-block but-full"
																			v-on:click="addProduct()">إضافة المزيد </div>
																	</div>
																	</div>
																	<div class="col-md-12">
																		<label class="form-label"> الزبون</label>
																		<textarea required class="form-control " v-model="customer" rows="2"></textarea>
																	</div>
																	<div class="col-md-12">
																		<label class="form-label"> الملاحظات </label>
																		<textarea class="form-control " v-model="notes" rows="2"></textarea>
																	</div>


																	<section>
																		<table id="responsive-data-table" class="table"
																			style="width:100%">
																			<thead>
																				<tr>
																					<th> رمز المنتح </th>
																					<th>الطول</th>
																					<th>العرض</th>
																					<th> العدد المطلوب</th>
																					<th> اجراء </th>
																				</tr>
																			</thead>

																			<tbody>
																				<tr v-for="(product , index) in OrderProducts" :key="product.id">
																					<td> {{product.prdCode}} </td>
																					<td v-if="product.prdHight != 0">{{ product.prdHight }}</td>
																					<td v-if="product.prdWidth != 0">{{ product.prdWidth }}</td>
																					<td v-if="product.prdHight == 0">------</td>
																					<td v-if="product.prdWidth == 0">------</td>
																					<td> {{product.prdCount}} </td>
																					
																					
																					<td>
																						<div class="btn-group mb-1">
																							<button type="button"
																								class="btn btn-danger" v-on:click="deleteProduct(index)">
																								<span class="sr-only"> حذف
																								</span>
																							</button>
																						</div>
																					</td>
																				</tr>

																			</tbody>
																		</table>
																	</section>

																	<div class="col-md-12">
																		<div v-on:click="PostOrder()" class="btn btn-success btn-block but-full"> حفظ
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> <!-- End Content -->
							</div> <!-- End Content Wrapper -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import vSelect from "vue-select";


export default {
	name: 'add-order',
	data() {
    return {
		productSearchOptions:[],
        products:[],
        cartProducts:[],
        first:true,
		prdCode:'',
		prdCount:1,
		OrderProducts:[],
		orderId:'',
		product_id:'',
		productsOptions:[],
		productCode:[],
		categories:[],
		selected_category:[],
		selected_prd:[],
		prdWidth:0,
		prdHight:0,
		customer:'',
		notes:''
      	
    };
  },
  components: {
	vSelect
  },
   mounted(){
		this.get_categories();
        this.getProducts();
		// this.getProductsOption();
		
    },

	


	methods: {

		

		 get_categories(){
       
			 axios.get("categories",
	   {
	   headers:{
	   Authorization:'Bearer '+localStorage.getItem('token')
	   }
	   })
	   .then((response)=>{
	   this.categories=response.data.data;
	   this.selected_category=this.categories[0];
	   this.getProductsOption();
	   })
	   .catch((error)=>{
	   console.log(error)
	   })
},

		getProducts(){
			this.OrderProducts=[];
			if( JSON.parse(localStorage.getItem("products"))!==""){
				JSON.parse(	localStorage.getItem("products")).forEach(element => {
				this.OrderProducts.push(element);
			});
			}
		},


		getProductsOption(){
				this.productsOptions=this.selected_category.products;
				console.log(this.selected_category);
				if(this.selected_category.category_type_id==2)	
				
    			 {
               		 document.getElementById('height_field').style.display='block';
						document.getElementById('width_field').style.display='block';
           		 }
           		 else{
              		 document.getElementById('height_field').style.display='none';
					   document.getElementById('width_field').style.display='none';
					   this.prdHight = 0;
                       this.prdWidth= 0;
            }
			
		},
		
        addProduct(){
			// this.cartProducts=[];
            this.products=JSON.parse(localStorage.getItem("products") || null);
            this.quantities=JSON.parse(localStorage.getItem("quantities") || null);
    //         if(this.first==true){
    //             if( JSON.parse(localStorage.getItem("products"))!==""){

    //                 JSON.parse(	localStorage.getItem("products")).forEach(element => {
    //                     this.cartProducts.push(element);
    //                 });
    //             }
               
           
    //        this.first==false;

    //    }
	//    this.product_id=document.getElementsByClassName('product_id')[0].value;
	console.log('this.selected_prd');
		console.log(this.selected_prd);
		this.product_id=this.selected_prd.id;
		axios.get("products/"+this.product_id,
					{
					headers:{
					Authorization:'Bearer '+localStorage.getItem('token')
					}
					})
					.then((response)=>{
						this.productCode=response.data.data.code;
						if(this.product_id !="" && this.prdCount!="")
				{
				
                this.OrderProducts.push(
                    {prdId:this.product_id,
                     prdCount:this.prdCount,
					 prdCode:this.productCode,
					 prdWidth:this.prdWidth,
					 prdHight:this.prdHight,
                   
                    });
			    let productsParsed = JSON.stringify(this.OrderProducts);
                localStorage.setItem('products', productsParsed);
				this.getProducts();
				}
					})
					.catch((error)=>{
					console.log(error)
					})

				
				
            },
            

       

       PostOrder(){
 		if( JSON.parse(localStorage.getItem("products"))!==""){
			
			
			// let customer=document.getElementsByClassName('customer')[0].value;
			// let notes=document.getElementsByClassName('notes')[0].value;
		
			const formData = new FormData();
            formData.append('customer', this.customer);
            formData.append('notes', this.notes);
		
		    axios.post('order',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(( response ) => {
				
				this.orderId=response.data.data.id;
				JSON.parse(	localStorage.getItem("products")).forEach(element => {
                       
					let prformData = new FormData();
					prformData.append('order_id', this.orderId);
					prformData.append('product_id', element.prdId);
					prformData.append('count', element.prdCount);
					prformData.append('width', element.prdWidth);
					prformData.append('hight', element.prdHight);


					axios.post('order-products',prformData,{
					headers:{
						Authorization:'Bearer '+localStorage.getItem('token'),

					}
			})
			
				}
				)
			
			  $('#response1').addClass("alert alert-success");
			  document.getElementById("response1").innerHTML = "تمت إضافة الطلب  بنجاح";
			  document.getElementById("response1").style.background = "#4bad48";   
			   this.$router.push('/view-orders');
               
			} )
			.catch((error)=>{
			     console.log(error)
				
				})

                  
                }

				else{
		

			let customer=document.getElementsByClassName('customer')[0].value;
			let notes=document.getElementsByClassName('notes')[0].value;
		
			const formData = new FormData();
            formData.append('customer', customer);
            formData.append('notes', notes);
		
		    axios.post('order',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(( response ) => {
				
				this.orderId=response.data.data.id;
				// let prdId=document.getElementsByClassName('prdId')[0].value;
				
				let prdId=this.selected_prd.id;
				
				
				let prdCount=document.getElementsByClassName('prdCount')[0].value;
                       
					let prformData = new FormData();
					prformData.append('order_id', this.orderId);
					prformData.append('product_id', prdId);
					prformData.append('count', prdCount);
					prformData.append('width', prdWidth);
					prformData.append('hight',prdHight);

					axios.post('order-products',prformData,{
					headers:{
						Authorization:'Bearer '+localStorage.getItem('token'),

					}
			
			
				}
				)
			
			  $('#response1').addClass("alert alert-success");
          	
			  document.getElementById("response1").innerHTML = "تمت إضافة الطلب  بنجاح";
			 // const router=useRouter();
			  this.$router.push('/view-orders');
               
			} )
			.catch((error)=>{
			     console.log(error)
				
				})


				}
	   },


	 
	   deleteProduct: function (index) {

            this.OrderProducts.splice(index ,1);

             localStorage.setItem('products',JSON.stringify(this.OrderProducts));


        },

	   
	},
	setup(){
			let products=JSON.stringify("");
		
		localStorage.setItem('products',products);	
		
	
		
		},

}
       
</script>

<style>
.form-label {
	font-size: 18px;
	font-weight: 600;
}


.but-full {
	width: inherit;
	font-size: 18px;
	font-weight: 600;
}

.vs__dropdown-toggle{
	margin-top: 1%;
	height: 40px;
}

@import "vue-select/dist/vue-select.css";

</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> تفاصيل الطقم </h1>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <h4>بحث في المنتجات</h4>
                                            <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                            <br>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th> صورة المنتج </th>
                                                        <th> اسم المنتج </th>
                                                        <th>رمز المنتج </th>
                                                        <th> عدد القطع </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr  v-for=" (product ,index) in products">
                                                        <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image">
                                                        <td>  {{product.product.name}} </td>
                                                        <td> {{product.product.code}} </td>
                                                        <td> {{product.qty}} </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                                                                           
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- End Content Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
      import { useRouter,useRoute } from 'vue-router'
      import axios from 'axios';

export default {
    name: 'package-detile',
    data() {
    return {
    id:'',
    products:[],

    }
}, 

mounted(){
    const route = useRoute();
    this.id = route.params.id;
    this.get_package_products();
   },

methods:{


    get_package_products(){
       
       axios.get("package/"+this.id,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
        
       this.products=response.data.data.products;
       console.log('this.products');
       console.log(this.products);

       })
       .catch((error)=>{
       console.log(error)
       })
},


},
 
};

    $(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});

   
</script>

<style>


.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.product-image{
    width:150px;
    height: 150px;
}
</style>
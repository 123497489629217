<template>
  <div class="app align-content-stretch d-flex flex-wrap">
    <div class="app-sidebar">
      <div class="logo">
        <a href="" class="logo-icon"
          ><span class="logo-text"> A2ZTR </span></a
        >
        <div class="sidebar-user-switcher user-activity-online">
          <a href="#">
            <img src="" />
            <span class="activity-indicator"></span>
            <span class="user-info-text"
              >Chloe<br /><span class="user-state-info">On a call</span></span
            >
          </a>
        </div>
      </div>
      <div class="app-menu" v-if="isAuth && !LoggedOut">
        <ul class="accordion-menu">
          <li class="sidebar-title"></li>
          <li class="active-page">
            <router-link :to ="{ name: 'Home' }" class="active"><i class="material-icons-two-tone">dashboard</i> لوحة التحكم</router-link>
          </li>
          <li>
           <router-link :to ="{ name: 'catgory' }"><i class="material-icons-two-tone">view_agenda</i> ادارة التصنيفات</router-link>
            </li>

          <li>
            <router-link :to ="{ name: 'kinds' }"><i class="material-icons-two-tone">view_day</i> ادارة الانواع</router-link>
          </li>
          <li>
            <!-- <span class="badge rounded-pill badge-success float-end">14</span> -->
            <router-link :to ="{ name: 'detiles' }"><i class="material-icons-two-tone">menu</i> ادارة التفاصيل</router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'add-product' }"><i class="material-icons-two-tone">menu</i> اضافة منتجات</router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'products-list' }"><i class="material-icons-two-tone">menu</i>  قائمة المنتجات</router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'product-code-menu' }"><i class="material-icons-two-tone">menu</i>  اكسل المنتجات </router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'add-records' }"><i class="material-icons-two-tone">menu</i> إضافة سجل منتجات</router-link>
          </li>
         
          <li>
            <router-link :to ="{ name: 'quary_product' }"><i class="material-icons-two-tone">menu</i> الاستعلام عن المنتجات </router-link>
          </li>

          <li>
            <a class="men-label" >
              <i class="material-icons-two-tone"></i>  الأطقم  </a
            >
          </li>

          <li>
            <router-link :to ="{ name: 'add-packages' }"><i class="material-icons-two-tone">card_giftcard</i> إضافة طقم</router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'view-package' }"><i class="material-icons-two-tone">card_giftcard</i> قائمة الأطقم </router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'query-package' }"><i class="material-icons-two-tone">card_giftcard</i> استعلام عن طقم </router-link>
          </li>
        
          <label  class="men-label badge float-end"> <br></label>
          <li>
            <a class="men-label" >
              <i class="material-icons-two-tone"></i> ادارة الطلبات  </a
            >
          </li>
          <li>
            <router-link :to ="{ name: 'add-order' }"><i class="material-icons-two-tone">menu</i> اضافة طلبات</router-link>
          </li>

          <li>
            <router-link :to ="{ name: 'view-orders' }"><i class="material-icons-two-tone">menu</i> قائمة الطلبات </router-link>
          </li>

          <!-- <li>
            <router-link :to ="{ name: 'order-detile' }"><i class="material-icons-two-tone">menu</i> تفاصيل الطلب </router-link>
          </li> -->
          <li>
            <a class="men-label" >
              <i class="material-icons-two-tone"></i>  المبيعات  </a
            >
          </li>

          <li>
            <router-link :to ="{ name: 'sales' }"><i class="material-icons-two-tone">analytics</i> فاتورة المبيعات </router-link>
            <router-link :to ="{ name: 'view-sales' }"><i class="material-icons-two-tone">analytics</i>قائمة المبيعات</router-link>
          </li>

          <li>
            <a class="men-label" >
              <i class="material-icons-two-tone"></i>  المشتريات  </a
            >
          </li>

          <li>
            <router-link :to ="{ name: 'purchases' }"><i class="material-icons-two-tone">card_giftcard</i> فاتورة المشتريات </router-link>
            <router-link :to ="{ name: 'view-purchase' }"><i class="material-icons-two-tone">card_giftcard</i> قائمة المشتريات </router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'stocks' }"><i class="material-icons-two-tone">grid_on</i>  المخزون </router-link>
          </li>
          <li>
            <router-link :to ="{ name: 'add-user' }"><i class="material-icons-two-tone">grid_on</i>  إدارة المستخدمين </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
  return {
		isAuth:false,
    username:'',
    LoggedOut:false,
  }
},

mounted(){
this.isLoogedin();
},
  methods:{
    isLoogedin(){
  let token=localStorage.getItem('token');
  if(token!=null)
  {
    this.isAuth=true;
    this.username=localStorage.getItem('username');
  }
 
  },
  isLoggedOut()
  {
   
  if(localStorage.getItem('loggedout')=='1')
  {
    this.LoggedOut=true;
    localStorage.setItem('loggedout','1');
  } 
  
},
  }
}
</script>

<style>

.men-label {

color: blue!important;

}
</style>
<template>

    <div class="app-container">
        <div class="app-content">
                <div class="content-wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> ادارة  التفاصيل</h1>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <form action="#" @submit.prevent="submit">  

                                <div class="card">
                                    <div class="card-body">
                                        <p class="card-description"></p>
                                        <div class="example-container">
                                            <div class="example-container">
                                                <div class="pro-group det-prod">
                                                    <div class="row">
                                                <div class="col-sm-6" id="response"></div>     
                                            </div>
                                                  <span class="input-group-text txt-prod"> <h5>   التفصيل </h5> </span>
                                                  <input type="text" name="name" aria-label="Last name" class="form-control  prod-inpu">
                                                  <span class="input-group-text txt-prod"> <h5>  التصنيف  </h5> </span>
                                                  <select class="form-select  prod-inpu" name="category_id" aria-label="Default select example">
                                                    <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
                                                    
                                                  </select>
                                                    <button type="submit" class="btn btn-primary"> <h5> اضافة  </h5> </button>
                                          
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h5 class="card-title"></h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-description"></p>
                                        <div class="example-container">
                                            <div class="example-content">
                                                <div class="table-responsive">
                                                    <ul class="list-group">
                                                        <li  v-for="detail in details" class="list-group-item">
                                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                                            <h6> {{detail.name}} </h6>
                                                            <div class="dropdown">
                                                                <button
                                                                  class="btn btn-primary dropdown-toggle"
                                                                  type="button"
                                                                  id="dropdownMenuButton"
                                                                  data-mdb-toggle="dropdown"
                                                                  aria-expanded="false"
                                                                >
                                                                  تعديل
                                                                </button>
                                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                  <li><a class="dropdown-item" href="#">Action</a></li>
                                                                  <li><a class="dropdown-item" href="#">Another action</a></li>
                                                                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                                </ul>
                                                              </div>
                                                        </li>
                                                      
                                                    </ul>
                                                </div>
                                            </div>
                                       </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    
    
    </template>
    
    <script>
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    export default {
      name: 'detiles',

      data() {
       return {
            categories:[],
            details:[]
            }
    },

    mounted(){
    this.get_categories();
    this.get_details();
    },


    setup(){
        const router=useRouter();
        const submit=async e =>{
		e.preventDefault()
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		const response=await axios.post('details',inputs,{headers:{
			Authorization:'Bearer '+localStorage.getItem('token')
            }}
			).then((response)=>{	
			console.log(response);
            $('#response').addClass("alert alert-success");
          	  document.getElementById("response").innerHTML =response.data.message;
			  document.getElementById("response").innerHTML = "تمت إضافة النوع  بنجاح";
          	  document.getElementById("response").style.color = "#4bad48";         
		})
		
	  .catch((error)=>{
	
	 })	;	
		
	  }
	
	  return {
		submit
	  }
    },

 methods:{
    get_categories(){
       
					axios.get("categories",
					{
					headers:{
					Authorization:'Bearer '+localStorage.getItem('token')
					}
					})
					.then((response)=>{
					this.categories=response.data.data;
					
					})
					.catch((error)=>{
					console.log(error)
					})
			 },


     get_details(){
       
       axios.get("details",
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
       this.details=response.data.data;
       
       })
       .catch((error)=>{
       console.log(error)
       })
},
    
            }
    };
    
    
    
    </script>
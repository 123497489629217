
<template>

    <div class="app-container">
                <div class="app-content">
                    <div class="content-wrapper">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col">
                                    <div class="page-description">
                                        <h1>  تسجيل مستخدم</h1>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="card-description"></p>
                                            <div class="example-container">
                                        <div class="example-content">
                                            <form action="#" @submit.prevent="submit">
                                                <div id="response"></div>
                                                <div class="row mb-10">
                                                    <label for="inputEmail3" class="col-sm-2 col-form-label"><h4> اسم المستخدم </h4></label>
                                                    <div class="col-sm-6">
                                                        <input type="text" name="name" class="form-control" id="inputCatgory">
                                                    </div>
                                                   
                                                </div>

                                                <div class="row mb-10">
                                                    <label for="inputEmail3" class="col-sm-2 col-form-label"><h4> البريد الإلكتروني  </h4></label>
                                                    <div class="col-sm-6">
                                                        <input type="email" name="email" class="form-control" id="inputCatgory">
                                                    </div>
                                                   
                                                </div>

                                                <div class="row mb-10">
                                                    <label for="inputEmail3" class="col-sm-2 col-form-label"><h4> كلمة المرور</h4></label>
                                                    <div class="col-sm-6">
                                                        <input type="password" name="password" class="form-control" id="inputCatgory">
                                                    </div>
                                                   
                                                </div>

                                                <div class="row mb-10">
                                                    <label for="inputEmail3" class="col-sm-2 col-form-label"><h4> تأكيد كلمة المرور </h4></label>
                                                    <div class="col-sm-6">
                                                        <input type="password" name="c_password" class="form-control" id="inputCatgory">
                                                    </div>
                                                   
                                                </div>
                                                <div class="row mb-10">
                                                    <div class="col-sm-4">
                                                        <button type="submit" class="btn btn-primary">  تسجيل المستخدم</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               </div>
                               </div>

                </div>
            </div>
        </div>
    
    
    </template>
    
    
    <script>
    import axios from 'axios';
    import { useRouter } from 'vue-router';

    export default {
      name: 'Register',
    
     setup(){
        const router=useRouter();
        const submit=async e =>{
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		
		const response=await axios.post('register',inputs)
        .then((response=>{
            console.log("register"+response);
            axios.defaults.headers.common['Authorization'] =  'Bearer ' + response.data.data.token;
		    localStorage.setItem('token',response.data.data.token);
		    console.log('Bearer ' + response.data.data.token);
            router.push('/');
        }))
		
	  .catch((error)=>{
		
	document.getElementById('response').innerHTML="البريد الإلكتروني أو كلمة المرور غير صالحين";
	document.getElementById('response').style.background="#edb2ad";
	 })	;	
		
	
	//     axios.get('user',{
	// 	headers:{
	// 		Authorization:'Bearer '+localStorage.getItem('token'),
	// 		Accept:'application/json'
	// 	}
	// 	 })
    //    .then((response)=>{
		
	// 	  router.push('/');
		
	//  })
	//  .catch((error)=>{
	// 	console.log(error)
	// 	console.log(localStorage.getItem('token'))
	//  })
		//await router.push('/');
	  }

	  return {
		submit
	  }
    },
  
   

};
</script>
    
   
    
    <style>
    
    
    
    </style>
<template>
    
            <div class="search">
                <form>
                    <input class="form-control" type="text" placeholder="Type here..." aria-label="Search">
                </form>
                <a href="#" class="toggle-search"><i class="material-icons">close</i></a>
            </div>
              <div class="app-header">
                <nav class="navbar navbar-light navbar-expand-lg">
                    <div class="container-fluid">
                        <div class="navbar-nav" id="navbarNav">
                        </div>
                        <div v-if="!isAuth" class="d-flex">
                          
                            <div class="col-sm-8">
                                <router-link :to="'/signin'">
                                 <button  class="btn btn-primary">  تسجيل الدخول</button>
                                </router-link>
                             </div>
                            
                             <div class="col-sm-8">
                                <router-link :to="'/register'">
                                 <button  class="btn btn-light">  تسجيل مستخدم</button>
                                 </router-link>
                             </div>
                        </div>

                        <div v-if="isAuth" class="d-flex">
                          
                          <div class="col-sm-12">
                              
                               <button  v-on:click="logout()" class="btn btn-primary">  تسجيل الخروج </button>
                            
                           </div>
                          
                          
                      </div>
                    </div>
                </nav>
            </div>
            
</template>

<script>
    import { useRouter } from 'vue-router';

export default {
        name: 'Header',
        data() {
  return {
	isAuth:false,
    username:'',
   
  }
},

mounted(){
this.isLoogedin();
},
  methods:{

    logout()
    {
         const router=useRouter();
         localStorage.removeItem('token');
         this.$router.push('/');
    },
    isLoogedin(){
    let token=localStorage.getItem('token');
    if(token!=null)
    {
        this.isAuth=true;
        this.username=localStorage.getItem('username');
    }
}
  }
}
</script>

<style>

</style>
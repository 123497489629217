import { createRouter,createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Pages/Home.vue'
import catgory from '../views/Pages/catgory.vue'
import kinds from '../views/Pages/kinds.vue'
import detiles from '../views/Pages/detiles.vue'
import signin from '../views/SignIn.vue'
import register from '../views/Pages/Register.vue'
import addProduct from '../views/Pages/add-product'
import addOrder from '../views/Pages/add-order'
import viewOrders from '../views/Pages/view-orders'
import productsList from '../views/Pages/products-list'
import OrderDetile from '../views/Pages/order-detile'
import salesPage from '../views/Pages/sales'
import purchasesPage from '../views/Pages/purchases'
import stocksPage from '../views/Pages/stocks'
import UpdateCategory from '../views/Pages/update-category'
import ViewSales from '../views/Pages/view-sales'
import ViewPurchase from '../views/Pages/view-purchase'
import UpdateProduct from '../views/Pages/update-product'
import PurchaseDetile from '../views/Pages/purchase-detile'
import SaleDetile from '../views/Pages/sale-detile'
import UpdateSale from '../views/Pages/update-sale'
import UpdatePurchase from '../views/Pages/update-purchase'
import addUser from '../views/Pages/add-user'
import UpdateUser from '../views/Pages/update-user'
import UpdateOrder from '../views/Pages/update-order'
import QuaryProduct from '../views/Pages/quary_product'
import ProductCodeMenu from '../views/Pages/product-code-menu.vue'
import AddPackages from '../views/Pages/add-packages'
import ViewPackage from '../views/Pages/view-package'
import PackageDetile from '../views/Pages/package-detile'
import UpdatePackage from '../views/Pages/update-package'
import QueryPackage from '../views/Pages/query-package'
import UpdateRecord from '../views/Pages/update-record'
import RecordDetile from '../views/Pages/record-detile'
import AddRecords from '../views/Pages/add-records'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/catgory',
    name: 'catgory',
    component: catgory
  },
  {
    path: '/kinds',
    name: 'kinds',
    component: kinds
  },
  {
    path: '/detiles',
    name: 'detiles',
    component: detiles
  },
  {
    path: '/signin',
    name: 'signin',
    component: signin
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },

  {
    path: '/add-product',
    name: 'add-product',
    component: addProduct
  },

  {
    path: '/add-order',
    name: 'add-order',
    component: addOrder
  },

  {
    path: '/view-orders',
    name: 'view-orders',
    component: viewOrders
  },

  {
    path: '/products-list',
    name: 'products-list',
    component: productsList
  },

  {
    path: '/order-detile/:id',
    name: 'order-detile',
    component: OrderDetile
  },

  {
    path: '/sales',
    name: 'sales',
    component: salesPage
  },

  {
    path: '/purchases',
    name: 'purchases',
    component: purchasesPage
  },

  {
    path: '/stocks',
    name: 'stocks',
    component: stocksPage
  },
  {
    path: '/update-category/:id',
    name: 'update-category',
    component: UpdateCategory
  },

  {
    path: '/update-product/:id',
    name: 'update-product',
    component: UpdateProduct
  },

  
  {
    path: '/view-sales',
    name: 'view-sales',
    component: ViewSales
  },

  {
    path: '/view-purchase',
    name: 'view-purchase',
    component: ViewPurchase
  },

  {
    path: '/purchase-detile/:id',
    name: 'purchase-detile',
    component: PurchaseDetile
  },

   {
    path: '/sale-detile/:id',
    name: 'sale-detile',
    component: SaleDetile
  },

  {
    path: '/update-sale/:id',
    name: 'update-sale',
    component: UpdateSale
  },

  {
    path: '/update-purchase/:id',
    name: 'update-purchase',
    component: UpdatePurchase
  },

  {
    path: '/add-user',
    name: 'add-user',
    component: addUser
  },

  {
    path: '/update-user/:id',
    name: 'update-user',
    component: UpdateUser
  },

  {
    path: '/update-order/:id',
    name: 'update-order',
    component: UpdateOrder
  },

  {
    path: '/quary_product',
    name: 'quary_product',
    component: QuaryProduct
  },

  {
    path: '/product-code-menu',
    name: 'product-code-menu',
    component: ProductCodeMenu
  },

  {
    path: '/update-record/:id',
    name: 'update-record',
    component: UpdateRecord
  },

  {
    path: '/record-detile/:id',
    name: 'record-detile',
    component: RecordDetile
  },

  {
    path: '/add-records',
    name: 'add-records',
    component: AddRecords
  },

  {
    path: '/add-packages',
    name: 'add-packages',
    component: AddPackages
  },

  {
    path: '/view-package',
    name: 'view-package',
    component: ViewPackage
  },

  {
    path: '/package-detile/:id',
    name: 'package-detile',
    component: PackageDetile
  },

  {
    path: '/update-package/:id',
    name: 'update-package',
    component: UpdatePackage
  },

  {
    path: '/query-package',
    name: 'query-package',
    component: QueryPackage
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

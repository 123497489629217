<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> اضافة منتجات </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ec-content-wrapper">
				<div class="content">
					<div class="row">
						<div class="col-12">
							<div class="row">
                                    <div class="col-sm-6" id="response"></div>     
                              </div>
							<div class="card card-default">
								<div class="card-body">
									<div class="row ec-vendor-uploads">
										<div class="col-lg-12">
											<div class="ec-vendor-upload-detail">
												<!-- enctype="multipart/form-data" -->
												<form class="row g-3" action="#"   @submit.prevent="submit">
													<div class="col-md-6">
														<label for="inputEmail4" class="form-label"> اسم المنتج </label>
														<input type="text" class="form-control slug-title" name="name" id="inputEmail4">
													</div>
													<div class="col-md-6">
														<label class="form-label"> تصنيف المنتج </label>
														<select class="form-select  prod-inpu" name="category_id" aria-label="Default select example">
                                                    <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
                                                    
                                                  </select>
													</div>
													<div class="col-md-12">
														<label for="slug" class="form-label"> رمز المنتج </label> 
														<div class="col-12">
															<input id="slug" name="code" class="form-control here set-slug" type="text">
														</div>
													</div>
													<div class="col-md-12">
														<label class="form-label"> الوصف</label>
														<textarea class="form-control" name="description" rows="2"></textarea>
													</div>
                                                    <div class="col-md-12 ">
                                                        <label class="form-label">   رابط صورة المنتج</label>
                                                        <!-- <input type="file" name="image"  id="imageUpload" class="form-control"
																 /> -->
<!--  id="imageUpload" -->
													 <input type="text" name="image"  class="form-control">
													</div>

													<div class="col-md-12">
														<button type="submit" class="btn btn-primary" > حفظ </button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> <!-- End Content -->


				<!-- <div class="content">
					<div class="row">
						<div class="col-12">
							<div class="card card-default">
								<div class="card-body">
									<div class="row ec-vendor-uploads">
										<div class="col-lg-12">
											<div class="row">
                                                <div class="col-sm-6" id="response1"></div>     
                                            </div>
											<div class="ec-vendor-upload-detail">
												<form id="form" class="row g-3" action="#" enctype="multipart/form-data">

													<div class="col-md-12 ">
                                                        <label class="form-label">إضافة منتجات من ملف اكسل</label>
                                                        <input type="file"  name="file"  id="imageUpload" class="form-control"
																 />
													</div>

													<div class="col-md-12">
														<button type="submit" class="btn btn-primary" @click="upload_products()" > تحميل الملف </button>
													</div>
															</form>
														</div>
													</div>
											   </div>
											</div>
										</div>
									</div>
								</div>
							</div> -->
			</div> <!-- End Content Wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// import { useRouter } from 'vue-router';
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'add-product',

  data() {
       return {
            categories:[],
            types:[],
			file:[]
            }
    },

	mounted(){
    this.get_categories();
    this.get_products();
    },


  methods:{
	upload_products(){
		var form = document.getElementById('form');
            var formData = new FormData(form);

           
		    axios.post('upload_products',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(( response ) => {
				$('#response').addClass("alert alert-success");
          	  document.getElementById("response1").innerHTML =response.data.message;
			  document.getElementById("response1").innerHTML = "تمت إضافة المنتجات  بنجاح";
          	  document.getElementById("response1").style.color = "#4bad48";   
               
			} )
			.catch((error)=>{
			     console.log(error)
				})
               
			},

	

	

    get_categories(){
       
					axios.get("categories",
					{
					headers:{
					Authorization:'Bearer '+localStorage.getItem('token')
					}
					})
					.then((response)=>{
					this.categories=response.data.data;
					
					})
					.catch((error)=>{
					console.log(error)
					})
			 },


     get_products(){
       
       axios.get("products",
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token'),
	  
       }
       })
       .then((response)=>{
	     
       })
       .catch((error)=>{
       console.log(error)
       })},
	},
    
            


  setup(){
        const router=useRouter();
        const submit=async e =>{
		e.preventDefault()
		const form =new FormData(e.target);
		const inputs=Object.fromEntries(form.entries());
		const response=await axios.post('products',inputs,{headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
			'Content-Type': `multipart/form-data;`
            }}
			).then((response)=>{	
			console.log(response);
            $('#response').addClass("alert alert-success");
          	  document.getElementById("response").innerHTML =response.data.message;
			  document.getElementById("response").innerHTML = "تمت إضافةالمنتج  بنجاح";
			  router.push('/products-list');        
		})
		
	  .catch((error)=>{
		console.log(error);

	 })	;	
		
	  }
	
	  return {
		submit
	  }
    },
}

</script>

<style>

.form-label {
    font-size: 18px;
    font-weight: 600;
}

</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> تفاصيل الطلب </h1>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <h4>بحث في المنتجات</h4>
                                            <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                            <br>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>صورة المنتج </th>
                                                        <th>اسم المنتج</th>
                                                        <th>رمز المنتج </th>
                                                        <th>الطول</th>
                                                        <th>العرض</th>
                                                        <th> العدد </th>
                                                        <th> الحالة </th>
                                                        <th>اجراء</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr  v-for="    (product ,index) in products">
                                                        <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image">
                                                        <td> {{product.product.name}} </td>
                                                        <td>  {{product.product.code}} </td>
                                                        <td v-if="product.hight == 0"> ------ </td>
                                                        <td v-if="product.width == 0"> ------ </td>
                                                        <td v-if="product.hight != 0"> {{product.hight}} </td>
                                                        <td v-if="product.width != 0"> {{product.width}} </td>
                                                        <td> {{product.count}} </td>
                                                        <td> {{product.status}} </td>
                                                        <td>
                                                            <div class="col-2" v-on:click="dropdown(index);">
																<div class="dropdown"  v-bind:class="{ pending: (product.status==0), accepted:(product.status==1),completed:(product.status==2)}">
																	
																	<button class="ch-selected "  v-if="product.status==0" >معلق <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="product.status==1" >مقبول <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="product.status==2" >مكتمل <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<div class="dropdown-content">
																	
																		<div class="row menu-choice" v-on:click="update_status(0,product.id)">
																				<div class="col-8">
																				معلق
																				</div>
																			</div>
																		

																			<div class="row menu-choice" v-on:click="update_status(1,product.id)">
																			
																				<div class="col-8">
																				مقبول
																				</div>
																				</div>

																				<div class="row menu-choice" v-on:click="update_status(2,product.id)">
																				<div class="col-8">
																				مكتمل
																				</div>
																			</div>
																		</div>
																											</div>
															
																</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                           
                                           <!-- <img src="https://lh4.googleusercontent.com/d/1gcVYAAOH_orX5-vjawOTUo-uROiVYG5-"> -->
                                                
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- End Content Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
      import { useRouter,useRoute } from 'vue-router'
      import axios from 'axios';

export default {
    name: 'order-detile',
    data() {
    return {
    id:'',
    products:[]
    }
}, 

methods:{

    update_status(status,prd_id)
	{
		

		const formData = new FormData();
            formData.append('status', status);
           

		const response= axios.post('update_prd_status/'+prd_id,formData,{headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
			
            }}
			).then((response)=>{	
			console.log(response);
           this.get_order_products();  
		
		})
		
	  .catch((error)=>{
		console.log(error);

	 })	;	
		

	},

    dropdown(i){
		for(let j=0; j<this.products.length;j++){
			if(j!==i)
		document.getElementsByClassName('dropdown-content')[j].style.display='none';
		}

	
        if(document.getElementsByClassName('dropdown-content')[i].style.display!=='block'){
            document.getElementsByClassName('dropdown')[i].style.display='block';
            document.getElementsByClassName('dropdown-content')[i].style.display='block';
        }

        else 
        {
            document.getElementsByClassName('dropdown-content')[i].style.display='none';

        }
       
    },
    get_order_products(){
       
       axios.get("order/"+this.id,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
       this.products=response.data.data.products;
       
       })
       .catch((error)=>{
       console.log(error)
       })
},


},
   mounted(){
    const route = useRoute();
    this.id = route.params.id;
    this.get_order_products();
   }
    };

    $(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});

   
</script>

<style>


.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.product-image{
    width:150px;
    height: 150px;
}
</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> تعديل منتج  </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ec-content-wrapper">
				<div class="content">
					<div class="row">
						<div class="col-12">
							<div class="row">
                                    <div class="col-sm-6" id="response"></div>     
                              </div>
							<div class="card card-default">
								<div class="card-body">
									<div class="row ec-vendor-uploads">
										<div class="col-lg-12">
											<div class="ec-vendor-upload-detail">
												<!-- enctype="multipart/form-data" -->
												<form class="row g-3" action="#" @submit.prevent="submit">
													<div class="col-md-6">
														<label for="inputEmail4" class="form-label"> اسم المنتج </label>
														<input type="text" class="form-control name slug-title" v-bind:value="product.name"  name="name" id="inputEmail4">
													</div>
													<div class="col-md-6">
														<label class="form-label"> تصنيف المنتج </label>
														<select class="form-select category_id  prod-inpu" v-bind:value="product.category_id" name="category_id" aria-label="Default select example">
                                                    <option v-for="category in categories" v-bind:value="category.id">{{ category.name }}</option>
                                                    
                                                  </select>
													</div>
													<div class="col-md-12">
														<label for="slug" class="form-label"> رمز المنتج </label> 
														<div class="col-12">
															<input id="slug" name="code"  v-bind:value="product.code"  class="form-control here code set-slug" type="text">
														</div>
													</div>
													<div class="col-md-12">
														<label class="form-label"> الوصف</label>
														<textarea class="form-control description"  v-bind:value="product.description" name="description" rows="2"></textarea>
													</div>
                                                    <div class="col-md-12 ">
                                                        <label class="form-label">   رابط صورة المنتج</label>
                                                        <!-- <input type="file" name="image"  id="imageUpload" class="form-control"
																 /> -->
<!--  id="imageUpload" -->
													 <input type="text" name="image"  class="form-control image" v-bind:value="product.image">
													</div>

													<div class="col-md-12">
														<button v-on:click="update_product()" class="btn btn-primary" > تعديل </button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> <!-- End Content -->


			
			</div> <!-- End Content Wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

    
    
    <script>
    import axios from 'axios';
    import { useRouter ,useRoute} from 'vue-router';
    
    export default {
      name: 'catgory',
      data() {
        return {
            categories:[],
            size_options:[],
            id:'',
            product:'',
            cat_size:''
            }
        },
    
        mounted(){
            this.get_categories();
           
            const route = useRoute();
            this.id = route.params.id;
            this.get_product();
        },
    
    setup(){
            const router=useRouter();
            const submit=async e =>{
            e.preventDefault()
        //     const form =new FormData(e.target);
        //     alert(12);
        //     const inputs=Object.fromEntries(form.entries());
        //     const response=await axios.put('categories/'+ this.id,inputs,{headers:{
        //         Authorization:'Bearer '+localStorage.getItem('token'),
        //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' ,
        //         }}
        //         ).then((response)=>{	
        //         console.log(response);
        //         alert(34);
        //         $('#response').addClass("alert alert-success");
        //             document.getElementById("response").innerHTML =response.data.message;
        //           document.getElementById("response").innerHTML = "تمت إضافة التصنيف  بنجاح";
        //           this.reload();        
        //     })
            
        //   .catch((error)=>{
        //     alert(56);
        //  })	;	
            
          }
        
          return {
            submit
          }
        },
    methods:{
    async update_product()
    {
        const form =new FormData();
          
            let name=document.getElementsByClassName('name')[0].value;
			let code=document.getElementsByClassName('code')[0].value;
            let category_id=document.getElementsByClassName('category_id')[0].value;
			let image=document.getElementsByClassName('image')[0].value;
            let description=document.getElementsByClassName('description')[0].value;
		
           
            form.append('name', name);
            form.append('code', code);
            form.append('category_id', category_id);
            form.append('image', image);
            form.append('description', description);
           
          
            const response=await axios.post('products/'+ this.id,form,{headers:{
                Authorization:'Bearer '+localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' ,
                }}
                ).then((response)=>{	
                console.log(response);
               
                $('#response').addClass("alert alert-success");
                    document.getElementById("response").innerHTML =response.data.message;
                  document.getElementById("response").innerHTML = "تم تعديل المنتج  بنجاح";
                 
            })
            
          .catch((error)=>{
          
         })	;	
    },

        set_options(){
            for(let i=1;i<1000; i++)
            {
                let j=i*0.1;
                this.size_options.push(j.toFixed(1));
            }
        },
        
        get_categories(){
           
                        axios.get("categories",
                        {
                        headers:{
                        Authorization:'Bearer '+localStorage.getItem('token')
                        }
                        })
                        .then((response)=>{
                        this.categories=response.data.data;
                        
                        })
                        .catch((error)=>{
                        console.log(error)
                        })
                 },


         get_product(){
           
           axios.get("products/"+this.id,
           {
           headers:{
           Authorization:'Bearer '+localStorage.getItem('token')
           }
           })
           .then((response)=>{
           this.product=response.data.data;
          
           
           })
           .catch((error)=>{
           console.log(error)
           })
    },
        
                }
    };
    
    </script>
    
    <style>
    .btn{
        margin-top:9%;
    }
    
    .old-info{
        padding-bottom: 3% !important;
    }
    
    </style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> تعديل السجل </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ec-content-wrapper">
                                <div class="content">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row ec-vendor-uploads">
                                                        <div class="col-lg-12">
                                                            <div class="ec-vendor-upload-detail">
                                                                <form class="row g-3">

                                                                    <div id="productsDiv">
                                                                        <div class="row">
                                                                            <div id="response1"></div>
                                                                            <div class="col-md-3">

                                                                                <label class="form-label"> الصنف
                                                                                </label>
                                                                                <select class="form-select  prod-inpu prdId"
                                                                                    v-model="selected_category"
                                                                                    v-on:change="getProductsOption()"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="category in categories"
                                                                                        v-bind:value="category">{{
                                                                                            category.name }}</option>

                                                                                </select>

                                                                            </div>

                                                                            <div class="col-md-3">
                                                                                <label class="form-label"> رمز المنتج
                                                                                </label>
                                                                                <select
                                                                                    class="form-select product_id prod-inpu"
                                                                                    name="product_id"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="prd in productsOptions"
                                                                                        v-bind:value="prd.id">{{ prd.code }}
                                                                                    </option>

                                                                                </select>
                                                                            </div>


                                                                        </div>
                                                                        <div class="col-md-12 text-center">
                                                                            <p> <br> يمكنك اضافة اكثر من منتج في نفس الوقت
                                                                                وحفظهم مرة واحدة <br></p>
                                                                            <div class="btn btn-primary button btn-lg btn-block but-full"
                                                                                v-on:click="addProduct()">إضافة المزيد
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   

                                                                    <section>
                                                                        <table id="responsive-data-table" class="table"
                                                                            style="width:100%">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th> التصنيف </th>
                                                                                    <th> رمز المنتح </th>
                                                                                    <th> إجراء </th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                <tr v-for="(product ,index) in RecordProducts"
                                                                                    :key="product.id">
                                                                                    <td>{{ product.cate_name }}</td>
                                                                                    <td> {{ product.prdCode }} </td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProductNew(index)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr v-for="product in product_in_record"
                                                                                    :key="product.id">
                                                                                    <td>{{ product.product.category_name }}</td>
                                                                                    <td> {{ product.product.code }} </td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProduct(product.id)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </section>

                                                                    <div class="col-md-12">
                                                                        <div v-on:click="UpdateRecord()"
                                                                            class="btn btn-success btn-block but-full">
                                                                            تعديل
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> <!-- End Content -->
                            </div> <!-- End Content Wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';

export default {
    name: 'update-record',

    data() {
        return {
            products: [],
            prdCode: '',
            RecordProducts: [],
            recordId: '',
            product_id: '',
            productsOptions: [],
            productCode: [],
            categories: [],
            selected_category: [],
            product_in_record: [],
            id: '',
            record: '',
            cate_name:'',
            category_name:'',
           
        }
    },

    mounted() {

        this.get_categories();
        this.getProducts();
        // this.getProductsOption();
        const route = useRoute();
        this.id = route.params.id;
        this.get_record();
        this.get_Products_in_record();

    },

    methods: {

        get_categories() {

            axios.get("categories",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {

                    this.categories = response.data.data;
                    this.selected_category = this.categories[0];
                    this.getProductsOption();
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getProducts() {
            this.RecordProducts = [];
            if (JSON.parse(localStorage.getItem("products")) !== "") {
                JSON.parse(localStorage.getItem("products")).forEach(element => {
                    this.RecordProducts.push(element);
                });
            }
        },

        getProductsOption() {

            this.productsOptions = this.selected_category.products;
            console.log(this.selected_category);

        },

        addProduct() {
            this.products = JSON.parse(localStorage.getItem("products") || null);
            this.quantities = JSON.parse(localStorage.getItem("quantities") || null);
        
            this.product_id = document.getElementsByClassName('product_id')[0].value;


            axios.get("products/" + this.product_id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {

                    this.category_name = response.data.data.category_name;
                    this.productCode = response.data.data.code;
                    if (this.product_id != "") {

                        this.RecordProducts.push(
                            {
                                prdId: this.product_id,
                                prdCode: this.productCode,
                                cate_name: this.category_name,
                            });
                        let productsParsed = JSON.stringify(this.RecordProducts);
                        localStorage.setItem('products', productsParsed);
                        this.getProducts();
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

                        console.log('this.RecordProducts');
                        console.log(this.RecordProducts);
        },


        get_record() {

            const response = axios.get("record/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.record = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })
        },

        UpdateRecord() {
            if (JSON.parse(localStorage.getItem("products")) !== "") {

                const formData = new FormData();

                axios.post('/record/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {

                        JSON.parse(localStorage.getItem("products")).forEach(element => {

                            let prformData = new FormData();
                            prformData.append('record_id', this.id);
                            prformData.append('product_id', element.prdId);

                            axios.post('record-products', prformData, {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('token'),

                                }
                            })

                        }
                        )

                        $('#response1').addClass("alert alert-success");
                        document.getElementById("response1").innerHTML = "تمت تعديل السجل  بنجاح";
                        document.getElementById("response1").style.background = "#4bad48";
                        this.$router.push('/product-code-menu');

                    })
                    .catch((error) => {
                        console.log(error)

                    })
            }

            else {

                const formData = new FormData();


                axios.post('/record/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {

                        $('#response1').addClass("alert alert-success");

                        document.getElementById("response1").innerHTML = "تمت تعديل الطقم  بنجاح";
                        this.$router.push('/product-code-menu');

                    })
                    .catch((error) => {
                        console.log(error)

                    })

            }
        },




        get_Products_in_record() {

            axios.get("products-in-record/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.product_in_record = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })

        },

        deleteProduct(id) {

            axios.delete("record-products/" + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                  
                    this.get_Products_in_record();
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        deleteProductNew: function (index) {

           this.RecordProducts.splice(index ,1);

            localStorage.setItem('products',JSON.stringify(this.RecordProducts));


        },

    },

    setup() {
        let products = JSON.stringify("");

        localStorage.setItem('products', products);
    },

};


</script>

<style>
.form-label {
    font-size: 18px;
    font-weight: 600;
}
</style>
<template>
	<div class="app align-content-stretch d-flex flex-wrap">
		<div class="app-container">
			<div class="app-content">
				<div class="content-wrapper">
					<div class="container">
						<div class="row">
							<div class="col">
								<div class="page-description">
									<h1>  فاتورة مبيع </h1>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="ec-content-wrapper">
								<div class="content">
									<div class="row">
										<div class="col-12">
											<div class="card card-default">
												<div class="card-body">
													<div class="row ec-vendor-uploads">
														<div class="col-lg-12">
															<div class="ec-vendor-upload-detail">
																<form class="row g-3">

																	<div id="productsDiv">
																		<div class="row">
																		  <div id="response1"></div>
																		  <div id="response_stocke"></div>
																			<div class="col-md-3">
																				
																				<label class="form-label"> الصنف
																				</label>
																				<select class="form-select  prod-inpu prdId"  v-model="selected_category" v-on:change="getProductsOption()" aria-label="Default select example">
                                                 									   <option v-for="category in categories" v-bind:value="category">{{ category.name }}</option>
                                                    
                                                								  </select>
									
																			</div>

																			<div class="col-md-3">
																				<label class="form-label"> رمز المنتج
																				</label>
															
																				  <v-select class="product_id" v-model="selected_prd"  @update:modelValue="getDimenesionsOptions()"  name="product_id" :options="productsOptionsArr" :value=id label=code></v-select>
																				
																			</div>
																			
																			<div class="col-md-2">
																				<label class="form-label"> الكمية </label>
																				<input v-model="prdQty" type="text"
																					class="form-control slug-title">
																			</div>


																			<div class="col-md-3" id="dimenesions_field">
																				
																				<label class="form-label"> أبعاد المنتج
																				</label>
																				<select class="form-select  prod-inpu prdId" v-model="selected_prd_dim_Options" aria-label="Default select example">
                                                 									   <option v-for="dimenesionsOpt in dimenesionsOptions" v-bind:value="dimenesionsOpt"> الطول : {{ dimenesionsOpt.hight }}   العرض :{{ dimenesionsOpt.width }}</option>
                                                    
                                                								  </select>
									
																			</div>


																			<!-- <div class="col-md-2" id="height_field">
																				<label class="form-label"> الطول:
																					</label>
																				<input v-model="prdHight" type="text"
																					class="form-control slug-title prdHight">

																			</div>

																			<div class="col-md-2" id="width_field">
																				<label class="form-label"> العرض:
																					</label>
																				<input v-model="prdWidth" type="text"
																					class="form-control slug-title prdWidth">

																			</div> -->

																		</div>
																		<div class="col-md-12 text-center">
																		<p>  <br> يمكنك اضافة اكثر من منتج في نفس الوقت وحفظهم مرة واحدة <br></p>
																		<div class="btn btn-primary button btn-lg btn-block but-full"
																			v-on:click="addProduct()">إضافة المزيد </div>
																	</div>
																	</div>
																	<div class="col-md-12">
																		<label class="form-label"> الزبون</label>
																		<textarea required class="form-control customer"
																			rows="2" v-model="customer"></textarea>
																	</div>

																	<div class="col-md-12">
																		<label class="form-label"> الملاحظات </label>
																		<textarea class="form-control notes" v-model="notes" rows="2"></textarea>
																	</div>


																	<section>
																		<table id="responsive-data-table" class="table"
																			style="width:100%">
																			<thead>
																				<tr>
																					<th> رمز المنتح </th>
																					<th>الطول</th>
																					<th>العرض</th>
																					<th> العدد المطلوب</th>
																					<th> اجراء </th>
																				</tr>
																			</thead>

																			<tbody>
																				<tr v-for="(product ,index) in SaleProducts" :key="product.id">
																					<td> {{product.prdCode}} </td>
																					<td v-if="product.prdHight != 0 && product.prdHight != null">{{ product.prdHight }}</td>
																					<td v-if="product.prdWidth != 0 && product.prdWidth != null">{{ product.prdWidth }}</td>
																					<td v-if="product.prdHight == 0 || product.prdHight == null">------</td>
																					<td v-if="product.prdWidth == 0 || product.prdWidth == null">------</td>
																					<td> {{product.prdQty}} </td>
																					
																					<td>
																						<div class="btn-group mb-1">
																							<button type="button"
																								class="btn btn-danger" v-on:click="deleteProduct(index)">
																								<span class="sr-only"> حذف
																								</span>
																							</button>
																						</div>
																					</td>
																				</tr>

																			</tbody>
																		</table>
																	</section>

																	<div class="col-md-12">
																		<div v-on:click="PostSale()" class="btn btn-success btn-block but-full"> حفظ
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> <!-- End Content -->
							</div> <!-- End Content Wrapper -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import vSelect from "vue-select";

export default {
	name: 'sales',

	data() {
		return {
		products:[],
        cartProducts:[],
        first:true,
		prdCode:'',
		prdQty:1,
		SaleProducts:[],
		saleId:'',
		product_id:'',
		productsOptions:[],
		productCode:[],
		categories:[],
		selected_category:[],
		selected_prd:[],
		prod_test_qty:'',
		productsOptionsArr:[],
		customer:'',
		notes:'',
		prdWidth:0,
		prdHight:0,
		dimenesionsOptions:[],
		selected_prd_dim_Options:[],
		
		}
	},

	components: {
	vSelect
  },

	mounted() {
		this.get_categories();
		this.getProducts();
		// this.getProductsOption();
		// this.getDimenesionsOptions();

	},

	methods: {

		get_categories() {

			axios.get("categories",
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.categories = response.data.data;
					this.selected_category = this.categories[0];
					// this.prod_test_qty = this.selected_category.products[0].qty;
					// console.log(this.prod_test_qty);
					this.getProductsOption();
				})
				.catch((error) => {
					console.log(error)
				})
		},

		getProducts(){
			this.SaleProducts=[];
			if( JSON.parse(localStorage.getItem("products"))!==""){
				JSON.parse(	localStorage.getItem("products")).forEach(element => {
				this.SaleProducts.push(element);
			});
			}
		},

		getProductsOption(){
			
			// this.productsOptions=this.selected_category.products;

			if(this.selected_category.category_type_id==2)		
				{
					   document.getElementById('dimenesions_field').style.display='block';
					   
			    }
			    else
			    {
					  document.getElementById('dimenesions_field').style.display='none';

					  this.prdHight = 0;
					  this.prdWidth= 0;
					  this.selected_prd_dim_Options = [] ;
		        }

			this.productsOptions=[];
			this.productsOptionsArr=[];
			
			axios.get("stocke_products_by_category/"+this.selected_category.id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log('res')
					console.log(response);
					this.productsOptions = Object.entries(response.data.data);
 
					this.productsOptions.forEach(element => {
						
					    this.productsOptionsArr.push({'id':element[1].id, 'code':element[1].code,'category_name':element[1].category_name,'qty':element[1].qty});
					});
					// this.selected_prd = this.productsOptionsArr[0];
					this.getDimenesionsOptions();
				})
				.catch((error) => {
					console.log(error)
				})
			console.log(this.selected_category);
		
		},


		getDimenesionsOptions(){
			console.log('selected_prd id')
			console.log(this.selected_prd.id);
			
			axios.get("products_by_width_hight/"+this.selected_prd.id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log('dimenesions res')
					console.log(response);
					this.dimenesionsOptions = response.data.data;
				})
				.catch((error) => {
					console.log(error)
				})
		},
		
		

		addProduct(){
			// this.cartProducts=[];
            this.products=JSON.parse(localStorage.getItem("products") || null);
            this.quantities=JSON.parse(localStorage.getItem("quantities") || null);
    //         if(this.first==true){
    //             if( JSON.parse(localStorage.getItem("products"))!==""){

    //                 JSON.parse(	localStorage.getItem("products")).forEach(element => {
    //                     this.cartProducts.push(element);
    //                 });
    //             }
               
           
    //        this.first==false;

    //    }
		this.product_id=this.selected_prd.id;		
		// this.prod_test_qty  = this.selected_prd.qty;
		// console.log(this.prod_test_qty);
		console.log('selected_prd');
		console.log(this.selected_prd);
		axios.get("products/"+this.product_id,
					{
					headers:{
					Authorization:'Bearer '+localStorage.getItem('token')
					}
					})
					.then((response)=>{
						console.log(response);
						this.productCode=response.data.data.code;
						if(response.data.data.category_type_id == 2)
						{
							this.prod_test_qty  = this.selected_prd_dim_Options.qty;
						}
						else
						{
							this.prod_test_qty  = response.data.data.qty;
						}
						// this.prod_test_qty  = response.data.data.qty;
						// this.prod_test_qty  = this.selected_prd_dim_Options.qty;
						console.log('this.prod_test_qty');
						console.log(this.prod_test_qty);

						if(this.product_id !="" && this.prdQty!="")
				{
					console.log('this.prod_test_qty');
			    	console.log(this.prod_test_qty);
					if(this.prdQty > this.prod_test_qty)
					{
                        $('#response_stocke').addClass("alert alert-primary");
			              document.getElementById("response_stocke").innerHTML = "  كمية المنتج المدخلة أكبر من الكمية المتوفرة بالمخزن الكمية المتوفرة هي : " + this.prod_test_qty;
						console.log(" كمية المنتج المدخلة أكبر من الكمية المتوفرة بالمخزن");
						
					}

					else
					{
						this.SaleProducts.push(
                    {
						 prdId:this.product_id,
                         prdQty:this.prdQty,
					     prdCode:this.productCode,
					     prdWidth:this.selected_prd_dim_Options.width,
					     prdHight:this.selected_prd_dim_Options.hight,
                   
                    });
			    let productsParsed = JSON.stringify(this.SaleProducts);
                localStorage.setItem('products', productsParsed);
				this.getProducts();
				$('#response_stocke').addClass("alert alert-success");
			              document.getElementById("response_stocke").innerHTML = " تمت إضافة المنتج بنجاح ";
						console.log(" تمت إضافة المنتج بنجاح ");
					
						
						this.prdHight = 0;
					    this.prdWidth= 0;

					}

                // this.cartProducts.push(
                //     {prdId:this.product_id,
                //      prdQty:this.prdQty,
				// 	 prdCode:this.productCode
                   
                //     });
			    // let productsParsed = JSON.stringify(this.cartProducts);
                // localStorage.setItem('products', productsParsed);
				// this.getProducts();
				}
					})
					.catch((error)=>{
					console.log(error)
					})

				
				
            },

			PostSale(){
 		if( JSON.parse(localStorage.getItem("products"))!==""){
			
			
			
		
			const formData = new FormData();
            formData.append('customer', this.customer);
            formData.append('notes', this.notes);
		
		    axios.post('sales',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(( response ) => {
				
				this.saleId=response.data.data.id;
				JSON.parse(	localStorage.getItem("products")).forEach(element => {
                       
					let prformData = new FormData();
					prformData.append('sale_id', this.saleId);
					prformData.append('product_id', element.prdId);
					prformData.append('qty', element.prdQty);
					prformData.append('width', element.prdWidth);
					prformData.append('hight', element.prdHight);

					axios.post('sale-products',prformData,{
					headers:{
						Authorization:'Bearer '+localStorage.getItem('token'),

					}
			})
			
				}
				)
			
			  $('#response1').addClass("alert alert-success");
			  document.getElementById("response1").innerHTML = "تمت إضافة فاتورة المبيعات  بنجاح";
			  document.getElementById("response1").style.background = "#4bad48"; 
			  setTimeout(() => {
				this.$router.push('/view-sales');
			  }, 500);  
			
               
			} )
			.catch((error)=>{
			     console.log(error)
				
				})

                  
                }

				else{
		
			let notes=document.getElementsByClassName('notes')[0].value;
		
			const formData = new FormData();
           
            formData.append('notes', notes);
		
		    axios.post('sales',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(( response ) => {
				
				this.saleId=response.data.data.id;
				
				let prdId=this.selected_prd.id;
				let prdQty=document.getElementsByClassName('prdQty')[0].value;
                       
					let prformData = new FormData();
					prformData.append('sale_id', this.saleId);
					prformData.append('product_id', prdId);
					prformData.append('count', prdQty);
					prformData.append('width', prdWidth);
					prformData.append('hight', prdHight);

					axios.post('sale-products',prformData,{
					headers:{
						Authorization:'Bearer '+localStorage.getItem('token'),

					}
			
			
				}
				)
			
			  $('#response1').addClass("alert alert-success");
          	
			  document.getElementById("response1").innerHTML = "تمت إضافة فاتورة المبيعات  بنجاح";
			 // const router=useRouter();
			//  this.$router.push('/view-sales');
			setTimeout(() => {
				this.$router.push('/view-sales');
			  }, 500);  
			
			} )
			.catch((error)=>{
			     console.log(error)
				
				})


				}
	   },

	   deleteProduct: function (index) {

            this.SaleProducts.splice(index ,1);

             localStorage.setItem('products',JSON.stringify(this.SaleProducts));


        },

	},

	setup(){
			let products=JSON.stringify("");
		
		localStorage.setItem('products',products);	
		
	
		
		},

};


</script>

<style>

.form-label {
    font-size: 18px;
    font-weight: 600;
}
.vs__dropdown-toggle{
	margin-top: 1%;
	height: 40px;
}
@import "vue-select/dist/vue-select.css";

</style>
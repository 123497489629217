<template>

<Header></Header>


       <SideBar></SideBar>


     <router-view></router-view>

</template>


<script>
import SideBar from '../components/frontEnd/Sidebar.vue';
import Header from '../components/frontEnd/Header.vue';


export default {
    name: "Master",
    components: { Header, SideBar },


setup(){
 
}
};
</script>

<style>



</style>


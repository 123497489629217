<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> قائمة المنتجات </h1>
                                </div>
                            </div>
                        </div>

                        
				<div class="content">
					<div class="row">
						<div class="col-12">
							<div class="card card-default">
								<div class="card-body">
									<div class="row ec-vendor-uploads">
										<div class="col-lg-12">
											<div class="row">
                                                <div class="col-sm-6" id="response1"></div>     
                                            </div>
											<div class="ec-vendor-upload-detail">
												<form id="form" class="row g-3" action="#" enctype="multipart/form-data">

													<div class="col-md-12 ">
                                                        <label class="form-label">إضافة منتجات من ملف اكسل</label>
                                                        <input type="file"  name="file"  id="imageUpload" class="form-control"
																 />
													</div>

													<div class="col-md-12">
														<button type="submit" class="btn btn-primary" @click="upload_products()" > تحميل الملف </button>
													</div>
															</form>
														</div>
													</div>
											   </div>
											</div>
										</div>
									</div>
								</div>
							</div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">

                                            <div class="col-md-4">

                                             <!-- <label class="form-label"> عرض المنتجات حسب التصنيف
                                             </label> -->
                                             <h4> عرض المنتجات حسب التصنيف </h4>

                                             <select class="form-select  prod-inpu prdId"
                                                 v-model="selected_category"
                                                 v-on:change="getProductsOption()"
                                                 aria-label="Default select example">
                                                 <option value="all_categories"> جميع المنتجات </option>
                                                      <option v-for="category in categories"
                                                  v-bind:value="category">{{category.name }}</option>

                                             </select>

                                             </div>
                                             <br>
                                            <h4>بحث في المنتجات</h4>
                                            <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                            <br>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>صورة المنتج </th>
                                                        <th>اسم المنتج</th>
                                                        <th>رمز المنتج </th>
                                                        <th>التصنيف</th>
                                                        <th>اجراء</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr v-for="product in products" :key=product.id>
                                                        <td><img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.image"></td>
                                                        <td> {{product.name}}</td>
                                                        <td>{{product.code}}</td>
                                                        <td> {{product.category_name}}</td>
                                                        <td>
                                                            <button class="btn btn-primary">
                                                                    <router-link :to ="'update-product/'+product.id">
                                                                                                        
                                                                            <span class="sr-only btn-text"> تعديل
                                                                        </span>
                                                                                                    
                                                                </router-link>
                                                            </button>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="paginate">
                                            <v-pagination
                                                v-model="page"
                                                :pages="pageCount"
                                                :range-size="1"
                                                active-color="blue"
                                                @update:modelValue="updateHandler"
                                            />
                                           <!-- <img src="https://lh4.googleusercontent.com/d/1Yhhl1b9kAoyGEDGtmODUFS_1lnnFfi74-"> -->
                                        </div>   
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- End Content Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import axios from 'axios';
import { useRouter } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'products-list',
    data() {
       return {
            categories:[],
            products:[],
			page:1,
            selected_category: [],
            // productsOptions: [],
            pageCount:1,
            products_length:1,
            }
    },

    components: {
        VPagination
  },
    mounted(){
        this.get_count_products();
        this.get_products(1);
        this.get_categories();
        this.getProductsOption();
    },


methods:{

    get_count_products() {        

        axios.get("products_count",
            {
        headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),        

                }
            })
            .then((response) => {
                this.products_length = response.data.data;
                console.log('this.products_length');
                console.log(this.products_length);
            })
            .catch((error) => {
              console.log(error)
            })

    },


    get_categories() {

        axios.get("categories",
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
        }
            })
            .then((response) => {
        this.categories = response.data.data;
                // this.selected_category = this.categories[0];
                this.selected_category = "all_categories";
                this.getProductsOption();
            })
            .catch((error) => {
                console.log(error)
            })
    },

    getProductsOption() {

        if(this.selected_category == "all_categories")
        {
            this.get_products(1);
        }
        else
        {
            this.products = this.selected_category.products;
             console.log(this.selected_category);
        }
    
     },

    updateHandler(){
       
        this.get_products(this.page);
        window.scrollTo(0, 0);
    },
   
	upload_products(){
		var form = document.getElementById('form');
            var formData = new FormData(form);

           
		    axios.post('upload_products',formData,{
			headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),

				}
			})
			.then(async ( response ) => {
				$('#response').addClass("alert alert-success");
          	  document.getElementById("response1").innerHTML =response.data.message;
			  document.getElementById("response1").innerHTML = "تمت إضافة المنتجات  بنجاح";
          	  document.getElementById("response1").style.color = "#4bad48";   
               await router.push('/products-list');
			} )
			.catch((error)=>{
			     console.log(error)
				})
               
	},

	

    
    async get_products(page){

       axios.get("products_paginate/"+page,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token'),
	  
       }
       })
       .then((response)=>{
	     this.products=response.data.data;
         console.log('this.products');
         console.log(this.products);

        //  this.products_length = this.products.length ,
         console.log('this.products_length');
         console.log(this.products_length);

         if(this.products_length%100 != 0)
         {
            this.pageCount = parseInt(this.products_length/100) + 1;
            console.log('this.pageCount');
            console.log(this.pageCount);
         }
         else
         {
            this.pageCount = parseInt(this.products_length/100);
            console.log('this.pageCount');
            console.log(this.pageCount);
         }


       })
       .catch((error)=>{
       console.log(error)
       })
    },

}   
};

$(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});
</script>

<style>
.btn-text{
    text-decoration: none;
    color:white;
}

.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.product-image{
    width:150px !important;
    height: 150px !important;
}

.paginate{
    text-align: center !important;
     width:70%;
    direction: ltr;
}

.paginate button{
    border:1px solid blue;
    padding: 20px;
}  
</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> تعديل الطلب </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ec-content-wrapper">
                                <div class="content">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row ec-vendor-uploads">
                                                        <div class="col-lg-12">
                                                            <div class="ec-vendor-upload-detail">
                                                                <form class="row g-3">

                                                                    <div id="productsDiv">
                                                                        <div class="row">
                                                                            <div id="response1"></div>
                                                                            <div class="col-md-4">

                                                                                <label class="form-label"> الصنف
                                                                                </label>
                                                                                <select class="form-select  prod-inpu prdId"
                                                                                    v-model="selected_category"
                                                                                    v-on:change="getProductsOption()"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="category in categories"
                                                                                        v-bind:value="category">{{
                                                                                            category.name }}</option>

                                                                                </select>

                                                                            </div>

                                                                            <div class="col-md-2">
                                                                                <label class="form-label"> رمز المنتج
                                                                                </label>
                                                                                <select
                                                                                    class="form-select product_id prod-inpu"
                                                                                    name="product_id"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="prd in productsOptions"
                                                                                        v-bind:value="prd.id">{{ prd.code }}
                                                                                    </option>

                                                                                </select>
                                                                            </div>

                                                                            <div class="col-md-2">
                                                                                <label class="form-label"> الكمية </label>
                                                                                <input v-model="prdQty" type="text"
                                                                                    class="form-control slug-title">
                                                                            </div>

                                                                            <div class="col-md-2" id="height_field">
																				<label class="form-label"> الطول:
																					</label>
																				<input v-model="prdHight" type="text"
																					class="form-control slug-title prdHight">

																			</div>

																			<div class="col-md-2" id="width_field">
																				<label class="form-label"> العرض:
																					</label>
																				<input v-model="prdWidth" type="text"
																					class="form-control slug-title prdWidth">

																			</div>

                                                                        </div>
                                                                        <div class="col-md-12 text-center">
                                                                            <p> <br> يمكنك اضافة اكثر من منتج في نفس الوقت
                                                                                وحفظهم مرة واحدة <br></p>
                                                                            <div class="btn btn-primary button btn-lg btn-block but-full"
                                                                                v-on:click="addProduct()">إضافة المزيد
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <label class="form-label"> الزبون</label>
                                                                        <textarea required class="form-control customer"
                                                                            rows="2" name="customer"
                                                                            v-model="order.customer"></textarea>
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <label class="form-label"> الملاحظات </label>
                                                                        <textarea class="form-control notes" rows="2"
                                                                            name="notes" v-model="order.notes"></textarea>
                                                                    </div>

                                                                    <!-- <div class="col-md-12">
                                                                        <input type="text" class="form-control customer"
                                                                            name="customer" v-model="order.customer"
                                                                            id="inputCustomer">
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <input type="text" class="form-control notes"
                                                                            name="notes" v-model="order.notes"
                                                                            id="inputNotes">
                                                                    </div> -->
                                                                    <section>
                                                                        <table id="responsive-data-table" class="table"
                                                                            style="width:100%">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th> رمز المنتح </th>
                                                                                    <th>الطول</th>
                                                                                    <th>العرض</th>
                                                                                    <th> العدد المطلوب</th>
                                                                                    <th> اجراء </th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                <tr v-for=" (product ,index)  in OrderProducts"
                                                                                    :key="product.id">
                                                                                    <td> {{ product.prdCode }} </td>
                                                                                    <td v-if="product.prdHight != 0 && product.prdHight != null">{{ product.prdHight }}</td>
																					<td v-if="product.prdWidth != 0 && product.prdWidth != null">{{ product.prdWidth }}</td>
																					<td v-if="product.prdHight == 0 || product.prdHight == null">------</td>
																					<td v-if="product.prdWidth == 0 || product.prdWidth == null">------</td>
                                                                                    <td> {{ product.prdQty }} </td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProductNew(index)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr v-for="product in product_in_order"
                                                                                    :key="product.id">
                                                                                    <td> {{ product.product.code }} </td>
                                                                                    <td v-if="product.hight == 0 || product.hight == null"> ------ </td>
                                                                                    <td v-if="product.width == 0 || product.width == null"> ------ </td>
                                                                                    <td v-if="product.hight != 0 && product.hight != null"> {{product.hight}} </td>
                                                                                    <td v-if="product.width != 0 && product.width != null"> {{product.width}} </td>
                                                                                    <td>{{ product.count }}</td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProduct(product.id)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </section>

                                                                    <div class="col-md-12">
                                                                        <div v-on:click="UpdateOrder()"
                                                                            class="btn btn-success btn-block but-full">
                                                                            تعديل
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> <!-- End Content -->
                            </div> <!-- End Content Wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';

export default {
    name: 'update-order',

    data() {
        return {
            products: [],
            cartProducts: [],
            first: true,
            prdCode: '',
            prdQty: 1,
            OrderProducts: [],
            orderId: '',
            product_id: '',
            productsOptions: [],
            productCode: [],
            categories: [],
            selected_category: [],
            product_in_order: [],
            id: '',
            customer: '',
            notes: '',
            order: '',
            prdHight:0,
            prdWidth:0,
        }
    },

    mounted() {

        this.get_categories();
        this.getProducts();
        // this.getProductsOption();
        const route = useRoute();
        this.id = route.params.id;
        this.get_order();
        this.get_Products_in_order();

    },

    methods: {

        get_categories() {

            axios.get("categories",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.categories = response.data.data;
                    this.selected_category = this.categories[0];
                    this.getProductsOption();
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getProducts() {
            this.OrderProducts = [];
            if (JSON.parse(localStorage.getItem("products")) !== "") {
                JSON.parse(localStorage.getItem("products")).forEach(element => {
                    this.OrderProducts.push(element);
                });
            }
        },

        getProductsOption() {

            this.productsOptions = this.selected_category.products;
            console.log(this.selected_category);
            if(this.selected_category.category_type_id==2)	
				
    			 {
               		 document.getElementById('height_field').style.display='block';
						document.getElementById('width_field').style.display='block';
           		 }
           		 else{
              		 document.getElementById('height_field').style.display='none';
					   document.getElementById('width_field').style.display='none';
                       this.prdHight = 0;
                       this.prdWidth= 0;

            }
        },

        addProduct() {
            // this.cartProducts = [];
            this.products = JSON.parse(localStorage.getItem("products") || null);
            this.quantities = JSON.parse(localStorage.getItem("quantities") || null);
            // if (this.first == true) {
            //     if (JSON.parse(localStorage.getItem("products")) !== "") {

            //         JSON.parse(localStorage.getItem("products")).forEach(element => {
            //             this.cartProducts.push(element);
            //         });
            //     }


            //     this.first == false;

            // }
            this.product_id = document.getElementsByClassName('product_id')[0].value;


            axios.get("products/" + this.product_id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.productCode = response.data.data.code;
                    if (this.product_id != "" && this.prdQty != "") {

                        this.OrderProducts.push(
                            {
                                prdId: this.product_id,
                                prdQty: this.prdQty,
                                prdCode: this.productCode,
                                prdWidth:this.prdWidth,
					            prdHight:this.prdHight,

                            });
                        let productsParsed = JSON.stringify(this.OrderProducts);
                        localStorage.setItem('products', productsParsed);
                        this.getProducts();
                    }
                })
                .catch((error) => {
                    console.log(error)
                })



        },


        get_order() {

            const response = axios.get("order/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.order = response.data.data;
                    this.customer = this.order.customer;
                    this.notes = this.order.notes;

                })
                .catch((error) => {
                    console.log(error)
                })
        },

        UpdateOrder() {
            if (JSON.parse(localStorage.getItem("products")) !== "") {

                const formData = new FormData();

                let customer = document.getElementsByClassName('customer')[0].value;
                let notes = document.getElementsByClassName('notes')[0].value;

                // const formData = new FormData();
                formData.append('customer', customer);
                formData.append('notes', notes);

                axios.post('/order/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {

                        JSON.parse(localStorage.getItem("products")).forEach(element => {

                            let prformData = new FormData();
                            prformData.append('order_id', this.id);
                            prformData.append('product_id', element.prdId);
                            prformData.append('count', element.prdQty);
                            prformData.append('width', element.prdWidth);
                            prformData.append('hight', element.prdHight);

                            axios.post('order-products', prformData, {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('token'),

                                }
                            })

                        }
                        )

                        $('#response1').addClass("alert alert-success");
                        document.getElementById("response1").innerHTML = "تمت تعديل  الطلب  بنجاح";
                        document.getElementById("response1").style.background = "#4bad48";
                        this.$router.push('/view-orders');

                    })
                    .catch((error) => {
                        console.log(error)

                    })


            }

            else {

                const formData = new FormData();

                let customer = document.getElementsByClassName('customer')[0].value;
                let notes = document.getElementsByClassName('notes')[0].value;

                // const formData = new FormData();

                formData.append('customer', customer);
                formData.append('notes', notes);

                axios.post('/order/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {


                        $('#response1').addClass("alert alert-success");

                        document.getElementById("response1").innerHTML = "تمت تعديل  الطلب  بنجاح";
                        // const router=useRouter();
                        this.$router.push('/view-orders');

                    })
                    .catch((error) => {
                        console.log(error)

                    })


            }
        },




        get_Products_in_order() {

            axios.get("products-in-order/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.product_in_order = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })

        },

        deleteProduct(id) {

            axios.delete("order-products/" + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    // this.getProducts();
                    this.get_Products_in_order();
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        deleteProductNew: function (index) {

            this.OrderProducts.splice(index ,1);

              localStorage.setItem('products',JSON.stringify(this.OrderProducts));


        },

    },

    setup() {
        let products = JSON.stringify("");

        localStorage.setItem('products', products);



    },

};


</script>

<style>
.form-label {
    font-size: 18px;
    font-weight: 600;
}
</style>
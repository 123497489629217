
<template>
    <div class="app-container">
        <div class="app-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="page-description">
                                <h1> ادارة المستخدمين</h1>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-description"></p>
                                    <div class="example-container">
                                        <div class="example-content">
                                            <form action="#" @submit.prevent="submit">
                                                <div class="col-sm-6" id="response">

                                                </div>
                                                <div class="row mb-12">
                                                    <div class="col-sm-3">
                                                        <label> الاسم </label>
                                                        <input type="text" class="form-control" v-model="name" id="inputName">
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label> البريد الالكتروني </label>
                                                        <input type="text" class="form-control" v-model="email"
                                                            id="inputEmail">
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label> كلمة السر </label>
                                                        <input type="password" class="form-control" v-model="password"
                                                            id="inputPassword">
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label> الصلاحية </label>
                                                        <select v-model="role" class="form-control">
                                                            <option value="2">Employee</option>
                                                            <option value="1">Admin</option>
                                                            <!-- <option v-for="role in roles " v-bind:value="role">{{ role }}</option> -->
                                                        </select>
                                                    </div>

                                                    <div class="col-sm-4">
                                                        <label> </label>
                                                        <button type="submit" class="btn btn-primary"  v-on:click="add_user()"> اضافة مستخدم جديد
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title"></h5>
                                </div>
                                <div class="card-body">

                                    <div class="example-container">
                                        <div class="example-content">
                                            <div class="table-responsive">


                                                <table id="responsive-data-table" class="table" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th> الاسم </th>
                                                            <th> البريد الالكتروني </th>
                                                            <th> الصلاحية </th>
                                                            <th> اجراء </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr v-for="user in users" :key="user.id">
                                                            <td> {{ user.name }} </td>
                                                            <td> {{ user.email }} </td>

                                                            <td v-if="user.role == 1">Admin</td>
                                                            <td v-else-if="user.role == 2">Employee</td>
                                                            <td>

                                                                <button class="btn btn-primary">
                                                                    <router-link :to="'update-user/' + user.id">

                                                                        <span class="sr-only btn-text"> تعديل
                                                                        </span>

                                                                    </router-link>
                                                                </button>

                                                                <button type="button" class="btn btn-danger mx-5"
                                                                    v-on:click="delete_user(user.id)">
                                                                    <span class="sr-only"> حذف
                                                                    </span>
                                                                </button>

                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
    
<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
    name: 'add-user',
    data() {
        return {
            users: [],
            // id:'',
            name:'',
            email:'',
            password:'',
            role:'',
        }
    },

    mounted() {
        this.get_users();
    },

    // setup() {
    //     const router = useRouter();
    //     const submit = async e => {
    //         e.preventDefault()
    //         const form = new FormData(e.target);
    //         const inputs = Object.fromEntries(form.entries());
    //         const response = await axios.post('users', inputs, {
    //             headers: {
    //                 Authorization: 'Bearer ' + localStorage.getItem('token')
    //             }
    //         }
    //         ).then((response) => {
    //             console.log(response);
    //             $('#response').addClass("alert alert-success");
    //             document.getElementById("response").innerHTML = response.data.message;
    //             document.getElementById("response").innerHTML = "تمت إضافة مستخدم  بنجاح";
    //             this.reload();
    //         })

    //             .catch((error) => {

    //             });

    //     }

    //     return {
    //         submit
    //     }
    // },
    methods: {

        get_users() {

            axios.get("users",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.users = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })
        },


        add_user(){

               axios.post('users',{

                name:this.name,
                email:this.email,
                password:this.password,
                role:this.role
               },
            
              {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            },
                this.name="",
                this.email="",
                this.password="",
                this.role="",
            ).then((response) => {
                console.log(response);
                $('#response').addClass("alert alert-success");
                document.getElementById("response").innerHTML = response.data.message;
                document.getElementById("response").innerHTML = "تمت إضافة مستخدم  بنجاح";
                this.reload();
            })

                .catch((error) => {

                });
               
      


        },


        delete_user(id) {

            axios.delete("users/" + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    // this.users = response.data.data;
                    this.get_users();

                })
                .catch((error) => {
                    console.log(error)
                })
        },

    }
};

</script>
    
<style>
.btn {
    margin-top: 9%;
}
</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> فاتورة مبيع </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ec-content-wrapper">
                                <div class="content">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row ec-vendor-uploads">
                                                        <div class="col-lg-12">
                                                            <div class="ec-vendor-upload-detail">
                                                                <form class="row g-3">

                                                                    <div id="productsDiv">
                                                                        <div class="row">
                                                                            <div id="response1"></div>
                                                                            <div id="response_stocke"></div>
                                                                            <div class="col-md-3">

                                                                                <label class="form-label"> الصنف
                                                                                </label>
                                                                                <select class="form-select  prod-inpu prdId"
                                                                                    v-model="selected_category"
                                                                                    v-on:change="getProductsOption()"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="category in categories"
                                                                                        v-bind:value="category">{{
                                                                                            category.name }}</option>

                                                                                </select>

                                                                            </div>

                                                                            <div class="col-md-3">
                                                                                <label class="form-label"> رمز المنتج
                                                                                </label>
                                                                                <!-- <select
                                                                                    class="form-select product_id prod-inpu"
                                                                                    name="product_id"
                                                                                    aria-label="Default select example">
                                                                                    <option v-for="prd in productsOptions"
                                                                                        v-bind:value="prd.id">{{ prd.code }}
                                                                                    </option>

                                                                                </select> -->

                                                                                <v-select class="product_id" v-model="selected_prd" @update:modelValue="getDimenesionsOptions()" name="product_id" :options="productsOptionsArr" :value=id label=code></v-select>
                                                                            </div>

                                                                            <div class="col-md-3">
                                                                                <label class="form-label"> الكمية </label>
                                                                                <input v-model="prdQty" type="text"
                                                                                    class="form-control slug-title">
                                                                            </div>


                                                                            <div class="col-md-3" id="dimenesions_field">
																				
																				<label class="form-label"> أبعاد المنتج
																				</label>
																				<select class="form-select  prod-inpu prdId" v-model="selected_prd_dim_Options" aria-label="Default select example">
                                                 									   <option v-for="dimenesionsOpt in dimenesionsOptions" v-bind:value="dimenesionsOpt"> الطول : {{ dimenesionsOpt.hight }}   العرض :{{ dimenesionsOpt.width }}</option>
                                                    
                                                								  </select>
									
																			</div>


                                                                        </div>
                                                                        <div class="col-md-12 text-center">
                                                                            <p> <br> يمكنك اضافة اكثر من منتج في نفس الوقت
                                                                                وحفظهم مرة واحدة <br></p>
                                                                            <div class="btn btn-primary button btn-lg btn-block but-full"
                                                                                v-on:click="addProduct()">إضافة المزيد
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <label class="form-label"> الزبون</label>
                                                                        <textarea required class="form-control customer"
                                                                            rows="2" name="customer"
                                                                            v-model="sale.customer"></textarea>
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <label class="form-label"> الملاحظات </label>
                                                                        <textarea class="form-control notes" rows="2"
                                                                            name="notes" v-model="sale.notes"></textarea>
                                                                    </div>

                                                                    <!-- <div class="col-md-12">
                                                                        <input type="text" class="form-control customer"
                                                                            name="customer" v-model="sale.customer"
                                                                            id="inputCustomer">
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <input type="text" class="form-control notes"
                                                                            name="notes" v-model="sale.notes"
                                                                            id="inputNotes">
                                                                    </div> -->
                                                                    <section>
                                                                        <table id="responsive-data-table" class="table"
                                                                            style="width:100%">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th> رمز المنتح </th>
                                                                                    <th>الطول</th>
                                                                                    <th>العرض</th>
                                                                                    <th> العدد المطلوب</th>
                                                                                    <th> اجراء </th>
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                                <tr v-for="(product ,index) in SaleProducts"
                                                                                    :key="product.id">
                                                                                    <td> {{ product.prdCode }} </td>
                                                                                    <td v-if="product.prdHight != 0 && product.prdHight != null">{{ product.prdHight }}</td>
																					<td v-if="product.prdWidth != 0 && product.prdWidth != null">{{ product.prdWidth }}</td>
																					<td v-if="product.prdHight == 0 || product.prdHight == null">------</td>
																					<td v-if="product.prdWidth == 0 || product.prdWidth == null">------</td>
																					
                                                                                    <td> {{ product.prdQty }} </td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProductNew(index)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr v-for="product in product_in_sale"
                                                                                    :key="product.id">
                                                                                    <td> {{ product.product.code }} </td>
                                                                                    <td v-if="product.hight == 0 || product.hight == null"> ------ </td>
                                                                                    <td v-if="product.width == 0 || product.width == null"> ------ </td>
                                                                                    <td v-if="product.hight != 0 && product.hight != null"> {{product.hight}} </td>
                                                                                    <td v-if="product.width != 0 && product.width != null"> {{product.width}} </td>
                                                                                    <td>{{ product.qty }}</td>
                                                                                    <td>
                                                                                        <div class="btn-group mb-1">
                                                                                            <button type="button"
                                                                                                class="btn btn-danger"
                                                                                                v-on:click="deleteProduct(product.id)">
                                                                                                <span class="sr-only"> حذف
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </section>

                                                                    <div class="col-md-12">
                                                                        <div v-on:click="UpdateSale()"
                                                                            class="btn btn-success btn-block but-full">
                                                                            تعديل
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> <!-- End Content -->
                            </div> <!-- End Content Wrapper -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import vSelect from "vue-select";

export default {
    name: 'update-sale',

    data() {
        return {
            products: [],
            cartProducts: [],
            first: true,
            prdCode: '',
            prdQty: 1,
            SaleProducts: [],
            saleId: '',
            product_id: '',
            productsOptions: [],
            productCode: [],
            categories: [],
            selected_category: [],
            product_in_sale: [],
            id: '',
            customer: '',
            notes: '',
            sale: '',
            prod_test_qty:'',
            selected_prd:[],
            productsOptionsArr:[],
            prdWidth:0,
		    prdHight:0,
		    dimenesionsOptions:[],
		    selected_prd_dim_Options:[],
        }
    },

    components: {
	vSelect
  },

    mounted() {

        this.get_categories();
        this.getProducts();
        // this.getProductsOption();
        const route = useRoute();
        this.id = route.params.id;
        this.get_sale();
        this.get_Products_in_sale();
        // this.getDimenesionsOptions();

    },

    methods: {

        get_categories() {

            axios.get("categories",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.categories = response.data.data;
                    this.selected_category = this.categories[0];
                    // this.prod_test_qty = this.selected_category.products[0].qty;
                    this.getProductsOption();
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getProducts() {
            this.SaleProducts = [];
            if (JSON.parse(localStorage.getItem("products")) !== "") {
                JSON.parse(localStorage.getItem("products")).forEach(element => {
                    this.SaleProducts.push(element);
                });
            }
        },

        getProductsOption(){
			

            if(this.selected_category.category_type_id==2)		
				{
					   document.getElementById('dimenesions_field').style.display='block';
					   
			    }
			    else
			    {
					  document.getElementById('dimenesions_field').style.display='none';

					  this.prdHight = 0;
					  this.prdWidth= 0;
                      this.selected_prd_dim_Options = [] ;
		        }
                
			// this.productsOptions=this.selected_category.products;
			this.productsOptions=[];
			this.productsOptionsArr=[];
			
			axios.get("stocke_products_by_category/"+this.selected_category.id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log('res')
					console.log(response);
					this.productsOptions = Object.entries(response.data.data);
 
					this.productsOptions.forEach(element => {
						
					    this.productsOptionsArr.push({'id':element[1].id, 'code':element[1].code,'category_name':element[1].category_name,'qty':element[1].qty});
					});

                    this.getDimenesionsOptions();
					
				})
				.catch((error) => {
					console.log(error)
				})
			console.log(this.selected_category);
		},


        getDimenesionsOptions(){
			console.log('selected_prd id')
			console.log(this.selected_prd.id);
			
			axios.get("products_by_width_hight/"+this.selected_prd.id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					console.log('dimenesions res')
					console.log(response);
					this.dimenesionsOptions = response.data.data;
				})
				.catch((error) => {
					console.log(error)
				})
		},


        addProduct() {
         
            this.products = JSON.parse(localStorage.getItem("products") || null);
            this.quantities = JSON.parse(localStorage.getItem("quantities") || null);
           
            this.product_id=this.selected_prd.id;	
            console.log('this.selected_prd');	
                console.log(this.selected_prd);	
		    // this.prod_test_qty  = this.selected_prd.qty;
		    // console.log(this.prod_test_qty);

            axios.get("products/" + this.product_id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.productCode = response.data.data.code;
                    if(response.data.data.category_type_id == 2)
						{
							this.prod_test_qty  = this.selected_prd_dim_Options.qty;
						}
						else
						{
							this.prod_test_qty  = response.data.data.qty;
						}
                    // this.prod_test_qty  = response.data.data.qty;
                    // this.prod_test_qty  = this.selected_prd_dim_Options.qty;
                     console.log('this.prod_test_qty');
                     console.log(this.prod_test_qty);
                    if (this.product_id != "" && this.prdQty != "") {

                        if(this.prdQty > this.prod_test_qty)
					{
                        console.log('this.prod_test_qty');
                         console.log(this.prod_test_qty);
                        $('#response_stocke').addClass("alert alert-primary");
                        document.getElementById("response_stocke").innerHTML = "  كمية المنتج المدخلة أكبر من الكمية المتوفرة بالمخزن الكمية المتوفرة هي : " + this.prod_test_qty;
						console.log(" كمية المنتج المدخلة أكبر من الكمية المتوفرة بالمخزن");
						
					}

					else
					{
                        this.SaleProducts.push(
                            {
                                prdId: this.product_id,
                                prdQty: this.prdQty,
                                prdCode: this.productCode,
                                prdWidth:this.selected_prd_dim_Options.width,
					            prdHight:this.selected_prd_dim_Options.hight,

                            });
                        let productsParsed = JSON.stringify(this.SaleProducts);
                        localStorage.setItem('products', productsParsed);
                        this.getProducts();
                        $('#response_stocke').addClass("alert alert-success");
			              document.getElementById("response_stocke").innerHTML = " تمت إضافة المنتج بنجاح ";
						console.log("تمت إضافة المنتج بنجاح");
                    }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })



        },


        get_sale() {

            const response = axios.get("sale/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.sale = response.data.data;
                    this.customer = this.sale.customer;
                    this.notes = this.sale.notes;

                })
                .catch((error) => {
                    console.log(error)
                })
        },

        UpdateSale() {
            if (JSON.parse(localStorage.getItem("products")) !== "") {

                const formData = new FormData();

                let customer = document.getElementsByClassName('customer')[0].value;
                let notes = document.getElementsByClassName('notes')[0].value;

                // const formData = new FormData();
                formData.append('customer', customer);
                formData.append('notes', notes);

                axios.post('/sale/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {

                        // this.saleId=response.data.data.id;
                        JSON.parse(localStorage.getItem("products")).forEach(element => {

                            let prformData = new FormData();
                            prformData.append('sale_id', this.id);
                            prformData.append('product_id', element.prdId);
                            prformData.append('qty', element.prdQty);
                            prformData.append('width', element.prdWidth);
				         	prformData.append('hight', element.prdHight);

                            axios.post('sale-products', prformData, {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('token'),

                                }
                            })

                        }
                        )

                        $('#response1').addClass("alert alert-success");
                        document.getElementById("response1").innerHTML = "تمت إضافة فاتورة المبيعات  بنجاح";
                        document.getElementById("response1").style.background = "#4bad48";
                        this.$router.push('/view-sales');

                    })
                    .catch((error) => {
                        console.log(error)

                    })


            }

            else {

                const formData = new FormData();

                let customer = document.getElementsByClassName('customer')[0].value;
                let notes = document.getElementsByClassName('notes')[0].value;

                // const formData = new FormData();

                formData.append('customer', customer);
                formData.append('notes', notes);

                axios.post('/sale/' + this.id, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                    .then((response) => {

                        // this.saleId=response.data.data.id;
                        // let prdId = document.getElementsByClassName('prdId')[0].value;
                        // let prdQty = document.getElementsByClassName('prdQty')[0].value;

                        // let prformData = new FormData();
                        // prformData.append('sale_id', this.id);
                        // prformData.append('product_id', prdId);
                        // prformData.append('count', prdQty);

                        // axios.post('/sale-products', prformData, {
                        //     headers: {
                        //         Authorization: 'Bearer ' + localStorage.getItem('token'),

                        //     }


                        // }
                        // )

                        $('#response1').addClass("alert alert-success");

                        document.getElementById("response1").innerHTML = "تمت تعديل فاتورة المبيعات  بنجاح";
                        // const router=useRouter();
                        this.$router.push('/view-sales');

                    })
                    .catch((error) => {
                        console.log(error)

                    })


            }
        },




        get_Products_in_sale() {

            axios.get("products-in-sale/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.product_in_sale = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })

        },

        deleteProduct(id) {

            axios.delete("sale-products/" + id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    // this.getProducts();
                    this.get_Products_in_sale();
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

       
        deleteProductNew: function (index) {

           this.SaleProducts.splice(index ,1);

            localStorage.setItem('products',JSON.stringify(this.SaleProducts));


        },

    },

    setup() {
        let products = JSON.stringify("");

        localStorage.setItem('products', products);



    },

};


</script>

<style>
.form-label {
    font-size: 18px;
    font-weight: 600;
}
</style>

<template>
    <div class="app-container">
        <div class="app-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="page-description">
                                <h1> ادارة المستخدمين</h1>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <p class="card-description"></p>
                                    <div class="example-container">
                                        <div class="example-content">
                                            <form action="#" @submit.prevent="submit">
                                                <div class="col-sm-6 " id="response">

                                                </div>
                                                <div class="row mb-12 ">
                                                    <!-- <label for="inputEmail3" class="col-sm-2 col-form-label"><h3> تعديل المستخدمين </h3></label> -->



                                                    <div class="row">

                                                        <div class="col-sm-3">
                                                            <label> تعديل الاسم</label>
                                                            <input type="text" class="form-control name" name="name"
                                                                v-bind:value="user.name" id="inputName">
                                                        </div>

                                                        <div class="col-sm-3">
                                                            <label> تعديل البريد الالكتروني </label>
                                                            <input type="text" class="form-control email" name="email"
                                                                v-bind:value="user.email" id="inputEmail">
                                                        </div>

                                                        <div class="col-sm-3">
                                                            <label> تعديل كلمة السر</label>
                                                            <input type="password" class="form-control password"
                                                                name="password" v-bind:value="user.password"
                                                                id="inputPassword">
                                                        </div>

                                                        <div class="col-sm-3">
                                                            <label> تعديل الصلاحية </label>
                                                            <select name="role" class="form-control role"
                                                                v-bind:value="user.role">
                                                                <option value="2">Employee</option>
                                                                <option value="1">Admin</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <label> </label>
                                                            <button v-on:click="update_user()" class="btn btn-primary">
                                                                تعديل المستخدم </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
    
<script>
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'update-user',
    data() {
        return {
            users: [],
            // size_options:[],
            id: '',
            user: '',
            name: '',
            email: '',
            password: '',
            role: '',
        }
    },

    mounted() {
        this.get_users();
        // this.set_options();
        const route = useRoute();
        this.id = route.params.id;
        this.get_user();
    },

    setup() {
        const router = useRouter();
        const submit = async e => {
            e.preventDefault()

        }

        return {
            submit
        }
    },
    methods: {
        async update_user() {
            const form = new FormData();

            let name = document.getElementsByClassName('name')[0].value;
            let email = document.getElementsByClassName('email')[0].value;
            let password = document.getElementsByClassName('password')[0].value;
            let role = document.getElementsByClassName('role')[0].value;


            form.append('name', name);
            form.append('email', email);
            form.append('password', password);
            form.append('role', role);

            const response = await axios.post('users/' + this.id, form, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                }
            }
            ).then((response) => {
                console.log(response);

                $('#response').addClass("alert alert-success");
                document.getElementById("response").innerHTML = response.data.message;
                document.getElementById("response").innerHTML = "تم تعديل المستخدم  بنجاح";
                this.reload();
            })

                .catch((error) => {

                });
        },

        get_users() {

            axios.get("users",
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.users = response.data.data;

                })
                .catch((error) => {
                    console.log(error)
                })
        },


        get_user() {

            axios.get("users/" + this.id,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.user = response.data.data;
                    this.name = this.user.name;
                    this.email = this.user.email;
                    this.password = this.user.password;
                    this.role = this.user.role;

                })
                .catch((error) => {
                    console.log(error)
                })
        },

    }
};

</script>
    
<style>
.btn {
    margin-top: 9%;
}

.old-info {
    padding-bottom: 3% !important;
}</style>
<template>
  <master></master>
</template>

<script>
import Master from './views/Master.vue';
export default {
components: {Master}
}
</script>


<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> قائمة الطلبات </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="tabs">

                                    <input type="radio" id="tab1" name="tab-control" checked>
                                    <input type="radio" id="tab2" name="tab-control">
									<input type="radio" id="tab3" name="tab-control">
                                    <ul>
										<li title="Features"><label for="tab1" role="button"><svg viewBox="0 0 24 24">
                                                    <path
                                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                                </svg><br><span> جميع الطلبات </span></label></li>

                                      

                                        <li title="Delivery Contents"><label for="tab2" role="button"><svg
                                                    viewBox="0 0 24 24">

                                                    <path
                                                        d="M2,10.96C1.5,10.68 1.35,10.07 1.63,9.59L3.13,7C3.24,6.8 3.41,6.66 3.6,6.58L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.66,6.72 20.82,6.88 20.91,7.08L22.36,9.6C22.64,10.08 22.47,10.69 22,10.96L21,11.54V16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V10.96C2.7,11.13 2.32,11.14 2,10.96M12,4.15V4.15L12,10.85V10.85L17.96,7.5L12,4.15M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V12.69L14,15.59C13.67,15.77 13.3,15.76 13,15.6V19.29L19,15.91M13.85,13.36L20.13,9.73L19.55,8.72L13.27,12.35L13.85,13.36Z" />
                                                </svg><br><span>  قيد التنفيذ </span></label></li>

												<li title="Features"><label for="tab3" role="button"><svg viewBox="0 0 24 24">
                                                    <path
                                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                                </svg><br><span> تم التجهيز</span></label></li>

                                    </ul>

                                    <div class="slider">
                                        <div class="indicator"></div>
                                    </div>
                                    <div class="content">
                             <section>
										<table id="responsive-data-table" class="table"
											style="width:100%">
											<thead>
												<tr>
													<th > رقم الطلب </th>
													<th >  الزبون </th>
													<th >   الملاحظات</th>
													<th> تعديل </th>
													<th >   الحالة</th>
													<th >   التفاصيل</th>
												</tr>
											</thead>

											<tbody>
												<tr v-for="(order, index) in orders" :key="order.id">
													<!-- <td><img class="tbl-thumb" src="assets/img/products/p1.jpg" alt="Product Image" /></td> -->
													<td>{{ order.id }}</td>
													<td>{{ order.customer }}</td>
													<td>{{ order.notes }}</td>
													<td v-if="order.status == 0">
                                                            <button class="btn btn-primary">
                                                                <router-link :to="'update-order/' + order.id">

                                                                    <span class="sr-only btn-text"> تعديل
                                                                    </span>

                                                                </router-link>
                                                            </button>
                                                        </td>
														<td v-else-if="order.status == 1">-----</td>
														<td v-else-if="order.status == 2">-----</td>
													<td>

														<div >
															
															<div class="col-2" v-on:click="dropdown(index);">
																<div class="dropdown"  v-bind:class="{ pending: (order.status==0), accepted:(order.status==1),completed:(order.status==2)}">
																	
																	<button class="ch-selected "  v-if="order.status==0" >معلق <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="order.status==1" >مقبول <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="order.status==2" >مكتمل <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<div class="dropdown-content">
																	
																		<div class="row menu-choice" v-on:click="update_status(0,order.id)">
																				<div class="col-8">
																				معلق
																				</div>
																			</div>
																		

																			<div class="row menu-choice" v-on:click="update_status(1,order.id)">
																			
																				<div class="col-8">
																				مقبول
																				</div>
																				</div>

																				<div class="row menu-choice" v-on:click="update_status(2,order.id)">
																				<div class="col-8">
																				مكتمل
																				</div>
																			</div>
																		</div>
																											</div>
															
																</div>
															<!-- <div class="dropdown-menu">
																<a class="dropdown-item" href="#" > قيد التنفيذ</a>
																<a class="dropdown-item" href="#" > ملغى </a>
															</div> -->
														</div>
													</td>
													<td>
														<!-- <router-link :to="order-detile"> 
														
													</router-link> -->
													<router-link :to ="{ name: 'order-detile',params:{id:order.id} }">تفاصيل الطلب </router-link>

												</td>
												</tr>

											</tbody>
										</table>
                                        </section>

										<section>
                                            <table id="responsive-data-table" class="table"
											style="width:100%">
											<thead>
												<tr>
													<th > صورة المنتح </th>
													<th > رمز المنتج </th>
													<th >  العدد المطلوب</th>
													<th >  المساحة الإجمالية </th>
													<th >  الحالة </th>
												</tr>
											</thead>

											<tbody>
												<tr v-for=" (product,index) in pending_products" :key="product.id">
													<td> <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image"></td>
													<td> {{product.product.code}}</td>
													<td>{{product.count}}</td>
													<td v-if="product.category_type=='space'" >{{product.size}}</td>
													<td v-if="product.category_type=='dimenesions'" >{{product.dimenesions}}</td>
													<td>
														<div >
															
															<div class="col-2" v-on:click="dropdown_prod(index);">
																<div class="dropdown_prod"  v-bind:class="{ pending: (product.status==0), accepted:(product.status==1),completed:(product.status==2)}">
																	
																	<button class="ch-selected "  v-if="product.status==0" >معلق <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="product.status==1" >مقبول <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<button class="ch-selected " v-if="product.status==2" >مكتمل <img class="drop-down-icon" src="/img/down-arrow.png"></button>
																	<div class="dropdown-prod-content">
																	
																		<div class="row menu-choice" v-on:click="update_prod_status(0,product.product_id)">
																				<div class="col-8">
																				معلق
																				</div>
																			</div>
																		

																			<div class="row menu-choice" v-on:click="update_prod_status(1,product.product_id)">
																			
																				<div class="col-8">
																				مقبول
																				</div>
																				</div>

																				<div class="row menu-choice" v-on:click="update_prod_status(2,product.product_id)">
																				<div class="col-8">
																				مكتمل
																				</div>
																			</div>
																		</div>
																	</div>
															
																</div>
																</div>
													</td>
												</tr>
												<tr>
													<td colspan="3">
														المساحة الإجمالية
													</td>
													<td>
														<!-- {{pending_list_size}} -->
														{{ pending_total_size_dimenesions }}
													</td>
													<td></td>
												</tr>
												<tr>
													<td></td>
												</tr>
											</tbody>
										</table>
                                        </section>


                                        <section>
                                            <table id="responsive-data-table" class="table"
											style="width:100%">
											<thead>
												<tr>
													<th > صورة  </th>
													<th > رمز المنتج </th>
													<th >  العدد المطلوب</th>
													<th >  المساحة الإجمالية </th>
													<th >  التاريخ </th>
												</tr>
											</thead>

											<tbody>
												<tr v-for=" (product,index) in prepared_products" :key="product.id">
													<td> <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image"></td>
													<td> {{product.product.code}}</td>
													<td>{{product.count}}</td>
													<td v-if="product.category_type=='space'" >{{product.size}}</td>
													<td v-if="product.category_type=='dimenesions'" >{{product.dimenesions}}</td>
													<td>{{product.order_product[0].prepared_date}}</td>
													
												</tr>
												<tr>
													<td colspan="3">
														المساحة الإجمالية
													</td>
													<td>
														<!-- {{prepared_list_size}} -->
														{{ prepared_total_size_dimenesions }}
													</td>
													<td></td>
												</tr>
												<tr>
													<td></td>
												</tr>
											</tbody>
										</table>
                                        </section>
									

										<div class="paginate">
                                            <v-pagination
                                                v-model="page"
                                                :pages="pageCount"
                                                :range-size="1"
                                                active-color="blue"
                                                @update:modelValue="updateHandler"/>
                                            
								        </div>

                                </div>

                            </div>
							
                        </div> <!-- End Content Wrapper -->
						
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></template>
  
<script>
 import axios from 'axios';
 import { useRouter } from 'vue-router';
 import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'view-orders',


data() {
    return {
        orders:[],
		pending_products:[],
		updated_prd:[],
		prepared_products:[],
		pending_list_size:0,
		prepared_list_size:0,
		pending_list_dimenesions:0,
		prepared_list_dimenesions:0,
		pending_total_size_dimenesions:0,
		prepared_total_size_dimenesions:0,
		page:1,
		pageCount:1,
        orders_length:1,
        }
    },

	components: {
        VPagination
  },

    mounted(){
		this.get_count_orders();
		this.get_orders(1);
		this.get_pending_list();
		this.get_prepared_list();
		
    },

methods:{

	updateHandler(){
       
	   this.get_orders(this.page);
	   window.scrollTo(0, 0);
   },


	dropdown(i){
		for(let j=0; j<this.orders.length;j++){
			if(j!==i)
		document.getElementsByClassName('dropdown-content')[j].style.display='none';
		}

	
        if(document.getElementsByClassName('dropdown-content')[i].style.display!=='block'){
            document.getElementsByClassName('dropdown')[i].style.display='block';
            document.getElementsByClassName('dropdown-content')[i].style.display='block';
        }

        else 
        {
            document.getElementsByClassName('dropdown-content')[i].style.display='none';

        }
       
    },

	dropdown_prod(i){
		for(let j=0; j<this.pending_products.length;j++){
			if(j!==i)
		document.getElementsByClassName('dropdown-prod-content')[j].style.display='none';
		}

	
        if(document.getElementsByClassName('dropdown-prod-content')[i].style.display!=='block'){
            document.getElementsByClassName('dropdown_prod')[i].style.display='block';
            document.getElementsByClassName('dropdown-prod-content')[i].style.display='block';
        }

        else 
        {
            document.getElementsByClassName('dropdown-prod-content')[i].style.display='none';

        }
       
    },


	get_count_orders() {

            axios.get("orders_count",
            	{
            		headers: {
            			Authorization: 'Bearer ' + localStorage.getItem('token'),

            		}
            	})
            	.then((response) => {
            		this.orders_length = response.data.data;
					console.log('this.orders_length');
					console.log(this.orders_length);
            	})
            	.catch((error) => {
            		console.log(error)
            	})

     },


	update_prod_status(status,prod_id)
	{
	
		let updated_prd=[];
		this.pending_products.filter(function(elem){
		
		
    if(elem.product_id == prod_id) 
	{
	
		updated_prd.push(elem);
	}
	
	updated_prd.forEach((element) => {
			//console.log(element.id)
			element.order_product.forEach((ord_prd) => {
			console.log(ord_prd.id)

				const formData = new FormData();
            	formData.append('status', status);
           

				const response= axios.post('update_prd_status/'+ord_prd.id,formData,{headers:{
					Authorization:'Bearer '+localStorage.getItem('token'),
			
           		 }}
				).then((response)=>{	
				console.log(response);
			
			})
		
			.catch((error)=>{
				console.log(error);

			})	;	
});
});
});


	
this.get_orders(1);  
 this.get_pending_list(); 	

	},

	update_status(status,order_id)
	{
		

		const formData = new FormData();
            formData.append('status', status);
           

		const response= axios.post('update_status/'+order_id,formData,{headers:{
			Authorization:'Bearer '+localStorage.getItem('token'),
			
            }}
			).then((response)=>{	
			console.log(response);
           this.get_orders(this.page);  
		   this.get_pending_list(); 
		   this.get_prepared_list();
		})
		
	  .catch((error)=>{
		console.log(error);

	 })	;	
		

	},
	
    get_orders(page){
       
					axios.get("orders_paginate/"+page,
					{
					headers:{
					Authorization:'Bearer '+localStorage.getItem('token')
					}
					})
					.then((response)=>{
					this.orders=response.data.data;


					// this.orders_length = this.orders.length ,
                    console.log('this.orders_length');
                    console.log(this.orders_length);
             
                    if(this.orders_length%100 != 0)
                    {
                       this.pageCount = parseInt(this.orders_length/100) + 1;
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }
                    else
                    {
                       this.pageCount = parseInt(this.orders_length/100);
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }
					
					})
					.catch((error)=>{
					console.log(error)
					})
			 },
    
            

	get_pending_list(){
       
	   axios.get("pending_list",
	   {
	   headers:{
	   Authorization:'Bearer '+localStorage.getItem('token')
	   }
	   })
	   .then((response)=>{
	   this.pending_products=response.data.data;
	    console.log(this.pending_products);
		this.pending_list_size = 0 ;
		this.pending_list_dimenesions = 0;
	   this.pending_products.forEach(element => {
		this.pending_list_size=parseFloat(element.size)+parseFloat(this.pending_list_size);
		this.pending_list_dimenesions=parseFloat(element.dimenesions)+parseFloat(this.pending_list_dimenesions);
	   });
	  this.pending_list_size=this.pending_list_size.toFixed(2);
	  this.pending_list_dimenesions=this.pending_list_dimenesions.toFixed(2);
	  this.pending_total_size_dimenesions =  parseFloat(this.pending_list_size) +parseFloat(this.pending_list_dimenesions);
	  this.pending_total_size_dimenesions = this.pending_total_size_dimenesions.toFixed(2);
	   })
	   .catch((error)=>{
	   console.log(error)
	   })
},

get_prepared_list(){
       
	   axios.get("prepared_list",
	   {
	   headers:{
	   Authorization:'Bearer '+localStorage.getItem('token')
	   }
	   })
	   .then((response)=>{
	   this.prepared_products=response.data.data;
	   console.log(this.prepared_products);
	   this.prepared_list_size = 0;
	   this.prepared_list_dimenesions = 0;
	   this.prepared_products.forEach(element => {
			this.prepared_list_size+=element.size;
			this.prepared_list_dimenesions=parseFloat(element.dimenesions)+parseFloat(this.prepared_list_dimenesions);
	   });
	   this.prepared_list_size=this.prepared_list_size.toFixed(2);
	   this.prepared_list_dimenesions=this.prepared_list_dimenesions.toFixed(2);
	  this.prepared_total_size_dimenesions =  parseFloat(this.prepared_list_size) +parseFloat(this.prepared_list_dimenesions);
	  this.prepared_total_size_dimenesions = this.prepared_total_size_dimenesions.toFixed(2);

	   })
	   .catch((error)=>{
	   console.log(error)
	   })
},
},

};
</script>

<style>
.table tbody tr:last-child td, .table tbody tr:last-child th {
    border: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;

}
.product-image{
	width:150px;
	height: 150px;
}

.dropdown-prod-content{
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;

}

.drop-down-icon{
width: 7px !important;
height: 7px !important;
margin-right:10px;
}


.dropdown, .dropdown_prod{
width:100px;
padding:10%;
text-align: center;
border-radius: 4px;


}

.pending{
	background-color: rgb(209, 60, 60);

}

.pending:hover{
	background-color: rgb(207, 107, 107);

}

.accepted{
	background-color: rgb(235, 161, 26);

}

.accepted:hover{
	background-color: rgb(223, 176, 88);

}

.completed{
	background-color: rgb(86, 170, 47);

}

.completed:hover{
	background-color:rgb(84, 150, 54);

}

.menu-choice:hover{
background-color: rgb(236, 231, 231);

}

.menu-choice{
	padding:5%
}

.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.paginate{
    text-align: center !important;
     width:70%;
    direction: ltr;
}
.paginate button{
    border:1px solid blue;
    padding: 20px;
} 
</style>
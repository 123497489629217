<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> قائمة المبيعات </h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <h4>بحث</h4>
                                            <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                            <br>
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>رقم الفاتوة</th>
                                                        <th> تاريخ </th>
                                                        <th> تفاصيل الفاتورة </th>
                                                        <th> الزبون </th>
                                                        <th> تعديل </th>
                                                        <th>اجراء</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr v-for="sale in sales" :key="sale.id">
                                                        <td> {{ sale.id }} </td>
                                                        <td> {{ sale.date }} </td>
                                                        <td>
                                                            <router-link
                                                                :to="{ name: 'sale-detile', params: { id: sale.id } }">تفاصيل
                                                                الفاتورة </router-link>
                                                        </td>
                                                        <td> {{ sale.customer }} </td>
                                                        <td>
                                                            <button class="btn btn-primary">
                                                                <router-link :to="'update-sale/' + sale.id">

                                                                    <span class="sr-only btn-text"> تعديل
                                                                    </span>

                                                                </router-link>
                                                            </button>
                                                        </td>
                                                        <td> طباعة </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div class="paginate">
                                            <v-pagination
                                                v-model="page"
                                                :pages="pageCount"
                                                :range-size="1"
                                                active-color="blue"
                                                @update:modelValue="updateHandler"
                                            />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'view-sales',

    data() {
        return {
            sales: [],
            page:1,
            pageCount:1,
            sales_length:1,
        }
    },

    components: {
        VPagination
  },

    mounted() {

        this. get_count_sales();
        this.get_sales(1);

    },

    methods: {


        get_count_sales() {        

            axios.get("sales_count",
                {
            headers: {
                         Authorization: 'Bearer ' + localStorage.getItem('token'),        

                      }
                   })
                   .then((response) => {
                       this.sales_length = response.data.data;
                       console.log('this.sales_length');
                       console.log(this.sales_length);
                })
                .catch((error) => {
                     console.log(error)
                })

        },


        updateHandler(){
       
       this.get_sales(this.page);
       window.scrollTo(0, 0);
   },


        get_sales(page) {

            axios.get("sales_paginate/"+page,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    this.sales = response.data.data;


                    // this.sales_length = this.sales.length ,
                    console.log('this.sales_length');
                    console.log(this.sales_length);
             
                    if(this.sales_length%100 != 0)
                    {
                       this.pageCount = parseInt(this.sales_length/100) + 1;
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }
                    else
                    {
                       this.pageCount = parseInt(this.sales_length/100);
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        },


    }

};


</script>

<style>
.table tbody tr:last-child td,
.table tbody tr:last-child th {
    border: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0;

}


.dropdown-prod-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;

}

.drop-down-icon {
    width: 7px !important;
    height: 7px !important;
    margin-right: 10px;
}


.dropdown,
.dropdown_prod {
    width: 100px;
    padding: 10%;
    text-align: center;
    border-radius: 4px;


}

.pending {
    background-color: rgb(209, 60, 60);

}

.pending:hover {
    background-color: rgb(207, 107, 107);

}

.accepted {
    background-color: rgb(235, 161, 26);

}

.accepted:hover {
    background-color: rgb(223, 176, 88);

}

.completed {
    background-color: rgb(86, 170, 47);

}

.completed:hover {
    background-color: rgb(84, 150, 54);

}

.menu-choice:hover {
    background-color: rgb(236, 231, 231);

}

.menu-choice {
    padding: 5%
}

.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.paginate{
    text-align: center !important;
     width:70%;
    direction: ltr;
}
.paginate button{
    border:1px solid blue;
    padding: 20px;
}  

</style>

<template>

    <div class="app-container">
                <div class="app-content">
                    <div class="content-wrapper">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col">
                                    <div class="page-description">
                                        <h1> ادارة التصنيفات</h1>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="card-description"></p>
                                            <div class="example-container">
                                        <div class="example-content">
                                            <form action="#" @submit.prevent="submit">    
                                                <div class="col-sm-6 " id="response">
    
                                                </div>                                      
                                                  <div class="row mb-12 ">
                                                    <label for="inputEmail3" class="col-sm-2 col-form-label"><h3> تعديل التصنيف </h3></label>
                                                      
                                                      
                                                  
                                                  <div class="row">

                                                 
                                                    <div class="col-sm-3">
                                                        <label> تعديل الاسم</label>
                                                        <input type="text" class="form-control name" name="name" v-model="cat.name" id="inputCatgory" >
                                                    </div>

                                                    <div class="col-sm-2">
                                                            <label>نوع التصنيف </label>
                                                            <select name="category_type" v-model="category_type" v-on:change="change_cagtegory_type()" class="form-control category_type" >
                                                                <option v-for="category_type in category_types "
                                                                    v-bind:value="category_type.id">{{ category_type.name }}</option>
                                                            </select>
                                                        </div>

                                                    <div class="col-sm-3" id="size_field">
                                                        <label> تعديل المساحة بالمتر المربع </label>
                                                       <select name="size" class="form-control size"  v-bind:value="cat_size">
                                                        <option v-for="size_option in size_options " v-bind:value="size_option">{{ size_option }}</option>
                                                       </select>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <label>      </label>
                                                        <button v-on:click="update_category()" class="btn btn-primary">   تعديل التصنيف </button>
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               </div>
                    </div>
                </div>
            </div>
        </div>
    
    
    </template>
    
    
    <script>
    import axios from 'axios';
    import { useRouter ,useRoute} from 'vue-router';
    
    export default {
      name: 'catgory',
      data() {
        return {
            categories:[],
            size_options:[],
            id:'',
            cat:'',
            cat_size:'',
            cat_type:'',
            category_type:'',
            category_types:[],
            }
        },
    
        mounted(){
            this.get_categories();
            this.set_options();
            const route = useRoute();
            this.id = route.params.id;
            this.get_category();
            this.get_categories_types();
        },
    
    setup(){
            const router=useRouter();
            const submit=async e =>{
            e.preventDefault()
        //     const form =new FormData(e.target);
        //     alert(12);
        //     const inputs=Object.fromEntries(form.entries());
        //     const response=await axios.put('categories/'+ this.id,inputs,{headers:{
        //         Authorization:'Bearer '+localStorage.getItem('token'),
        //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' ,
        //         }}
        //         ).then((response)=>{	
        //         console.log(response);
        //         alert(34);
        //         $('#response').addClass("alert alert-success");
        //             document.getElementById("response").innerHTML =response.data.message;
        //           document.getElementById("response").innerHTML = "تمت إضافة التصنيف  بنجاح";
        //           this.reload();        
        //     })
            
        //   .catch((error)=>{
        //     alert(56);
        //  })	;	
            
          }
        
          return {
            submit
          }
        },
    methods:{
    async update_category()
    {
        const form =new FormData();
          
            let name=document.getElementsByClassName('name')[0].value;
			let size=document.getElementsByClassName('size')[0].value;
            let category_type_id=document.getElementsByClassName('category_type')[0].value;
		
           if(category_type_id == 1)
           {
              form.append('name', name);
              form.append('size', size);
              form.append('category_type_id', category_type_id);
           }
           else
           {
              form.append('name', name);
              form.append('size', "");
              form.append('category_type_id', category_type_id);
           }
            
           
          
            const response=await axios.post('categories/'+ this.id,form,{headers:{
                Authorization:'Bearer '+localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' ,
                }}
                ).then((response)=>{	
                console.log(response);
               
                $('#response').addClass("alert alert-success");
                    document.getElementById("response").innerHTML =response.data.message;
                  document.getElementById("response").innerHTML = "تم تعديل التصنيف  بنجاح";
                  this.reload();        
            })
            
          .catch((error)=>{
          
         })	;	
    },

        set_options(){
            for(let i=1;i<1000; i++)
            {
                let j=i*0.1;
                this.size_options.push(j.toFixed(1));
            }
        },
        get_categories(){
           
                        axios.get("categories",
                        {
                        headers:{
                        Authorization:'Bearer '+localStorage.getItem('token')
                        }
                        })
                        .then((response)=>{
                        this.categories=response.data.data;
                        
                        })
                        .catch((error)=>{
                        console.log(error)
                        })
                 },


    get_category(){
           
           axios.get("categories/"+this.id,
           {
           headers:{
           Authorization:'Bearer '+localStorage.getItem('token')
           }
           })
           .then((response)=>{
           this.cat=response.data.data;
           console.log(this.cat);
           this.cat_size=this.cat.size;
           this.cat_type = this.cat.category_type_id;
           
           })
           .catch((error)=>{
           console.log(error)
           })
    },

    get_categories_types() {

axios.get("category_types",
    {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then((response) => {
        this.category_types = response.data.data;
        console.log('this.category_types');
        console.log(this.category_types);

    })
    .catch((error) => {
        console.log(error)
    })
},

change_cagtegory_type(){
if(this.category_type==2)
{
    document.getElementById('size_field').style.display='none'
}
else{
    document.getElementById('size_field').style.display='block'
}

},


        
                }
    };
    
    </script>
    
    <style>
    .btn{
        margin-top:9%;
    }
    
    .old-info{
        padding-bottom: 3% !important;
    }
    
    </style>
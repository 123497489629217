<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1>  الاستعلام عن المنتجات </h1>
                                </div>
                            </div>
                        </div>

                        <div class="row">

                                <div class="col-md-3">
                                      <h5> رمز المنتج</h5>

                                       <input class="form-control" type="text" name="package_code" v-model="package_code" placeholder="ادخل رمز المنتج">
                                </div> 


                              <div class="col-md-5">
								 <div v-on:click="get_record_products()"
								 	class="btn btn-primary">استعلام   
								 </div>
							  </div>

                              <h4 v-if="record_id != ' '">رقم السجل هو : {{ record_id }} </h4>

                        </div>

                         <br>

                        <div class="row">
                            <div class="col-12">
                                <div class="card card-default">
                                    <div class="card-body">
                                        <div class="container">
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th> صورة المنتج </th>
                                                        <th> اسم المنتج </th>
                                                        <th>رمز المنتج </th>
                                                        <th> إجراء </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="myTable">
                                                    <tr  v-for=" (product ,index) in products">
                                                       
                                                        <img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.product.image">
                                                       
                                                        <td>  {{product.product.name}} </td>

                                                        <td> {{product.product.code}} </td>

                                                        <td>
                                                            <button class="btn btn-primary"  v-on:click="download_image(product.product.image)">تحميل الصورة  </button>
                                                        </td>
                                                   
                                                    </tr>
                                                </tbody>
                                            </table>
                                                                                           
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- End Content Wrapper -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
      import { useRouter,useRoute } from 'vue-router'
      import axios from 'axios';
	  import vSelect from "vue-select";

export default {
    name: 'query-product',
    data() {
    return {
	record_id:' ',
    products:[],
    records:[],
	productsOptions: [],
	categories: [],
	selected_category: [],
	selected_prd:[],
	prd_id:'',
    package_code:'',

    }
}, 

components: {
	vSelect
  },

methods:{

    get_record_products(){
       
       axios.get("record/record_by_code/"+this.package_code,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
        
       this.products=response.data.data.products;
       console.log('this.products');
       console.log(this.products);
       
       this.get_record_number();

       })
       .catch((error)=>{
       console.log(error)
       })
    },

	get_record_number(){
       
       axios.get("record_number/"+this.package_code,
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
        
       this.record_id=response.data.data;
       console.log('this.record_id');
       console.log(this.record_id);

       })
       .catch((error)=>{
       console.log(error)
       })
    },

    download_image(img) {

        this.img_url = img;

        axios({
                  method: 'GET',
                  url: 'https://lh4.googleusercontent.com/d/' + this.img_url,
                  responseType: 'blob',  
              }).then((response) => {
                     const url = window.URL.createObjectURL(new Blob([response.data]));
                     const link = document.createElement('a');
                     link.href = url;
                     link.setAttribute('download', 'image.jpg');
                     document.body.appendChild(link);
                     link.click();
        });

    },

},
 
};

    $(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});

   
</script>

<style>


.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.product-image{
    width:150px;
    height: 150px;
}

.form-label {
	font-size: 18px;
	font-weight: 600;
}
.vs__dropdown-toggle{
	margin-top: 1%;
	height: 40px;
}

@import "vue-select/dist/vue-select.css";
</style>
<template>
    <div class="app align-content-stretch d-flex flex-wrap">
        <div class="app-container">
            <div class="app-content">
                <div class="content-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="page-description">
                                    <h1> قائمة المخزون </h1>
                                </div>
                            </div>
                        </div>


                        <div class="content">
                            <div class="row">
                                <div class="col-6">
                                    <h5>تصفية</h5>
                                    <!-- Filter options -->
                                    <!-- <div class="form-group">
                                        <select class="form-control" id="category">
                                            <option value=""> التصنيفات </option>
                                            <option v-for="category in categories" v-bind:value="category">{{ category.name }}</option>
                                        </select>
                                        <br>
                                    </div> -->


                                    
                                    <select class="form-select  prod-inpu prdId"
                                                 v-model="selected_category"
                                                 v-on:change="getProductsOption()"
                                                 aria-label="Default select example">
                                                 <option value="all_categories"> جميع المنتجات </option>
                                                      <option v-for="category in categories"
                                                  v-bind:value="category">{{category.name }}</option>

                                    </select>

                                </div>
                                <div class="col-6">
                                    <h5>بحث حسب رمز المنتج </h5>
                                    <input class="form-control" id="myInput" type="text" placeholder="بحث..">
                                    <br>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card card-default">
                                        <div class="card-body">
                                            <div class="container">
                                                <table class="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>صورة المنتج </th>
                                                            <th>رمز المنتج </th>
                                                            <th> العدد</th>
                                                            <th> الطول </th>
                                                            <th> العرض </th>
                                                            <th>التصنيف</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="myTable">
                                                        <!-- <tr v-for="product in products" :key=product.id>
                                                            <td><img class="product-image" v-bind:src="'https://lh4.googleusercontent.com/d/'+product.image"></td>
                                                            <td> {{ product.product.code }} </td>
                                                            <td>s </td>
                                                            <td> s </td>
                                                        </tr> -->

                                                        <tr v-for="stocke_prod in stocke_products" :key=stocke_prod.id>
                                                            <td><img class="product-image"
                                                                    v-bind:src="'https://lh4.googleusercontent.com/d/' + stocke_prod.image">
                                                            </td>
                                                            <td>{{ stocke_prod.code }}</td>
                                                            <td> {{stocke_prod.qty}}</td>
                                                            
                                                            <td v-if="stocke_prod.hight == 0 || stocke_prod.hight == null">-----</td>
                                                            <td v-if="stocke_prod.hight != 0 && stocke_prod.hight != null">{{stocke_prod.hight}}</td>

                                                            <td v-if="stocke_prod.width == 0 || stocke_prod.width == null">-----</td>
                                                            <td v-if="stocke_prod.width != 0 && stocke_prod.width != null">{{stocke_prod.width}}</td>

                                                            <!-- <td> {{stocke_prod.hight}}</td>
                                                            <td> {{stocke_prod.width}}</td> -->
                                                            <td> {{ stocke_prod.category_name }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div class="paginate">
                                            <v-pagination
                                                v-model="page"
                                                :pages="pageCount"
                                                :range-size="1"
                                                active-color="blue"
                                                @update:modelValue="updateHandler"
                                            />
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> <!-- End Content Wrapper -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


// export default {
//     name: 'stocks',

// }

import axios from 'axios';
import { useRouter } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'stocks',
    data() {
        return {
            stocke_products: [],
            categories:[],
            pgae:1,
            pageCount:1,
            stocke_products_length:1,
            selected_category: [],
            stocke_products_filter: [],
        }
    },

    components: {
        VPagination
  },

    mounted() {

        this.get_count_product_in_stocke();
        this.get_products_qty(1);
        this.get_categories();
        this.getProductsOption();
    },


    methods: {


        get_count_product_in_stocke() {        

            axios.get("count_product_in_stocke",
                {
            headers: {
                         Authorization: 'Bearer ' + localStorage.getItem('token'),        

                      }
                   })
                   .then((response) => {
                       this.stocke_products_length = response.data.data;
                       console.log('this.stocke_products_length');
                       console.log(this.stocke_products_length);
                })
                .catch((error) => {
                     console.log(error)
                })

        },


        get_categories(){
       
       axios.get("categories",
       {
       headers:{
       Authorization:'Bearer '+localStorage.getItem('token')
       }
       })
       .then((response)=>{
       this.categories=response.data.data;

       this.selected_category = "all_categories";
       this.getProductsOption();
       
       })
       .catch((error)=>{
       console.log(error)
       })
},

         getProductsOption() {         

              if(this.selected_category == "all_categories")
              {
                this.get_products_qty(1);
              }
              else
              {
                this.get_products_qty_with_filter(1);
              }

         },


         async get_products_qty_with_filter(page) {

                axios.get("/qty-products/"+page,
                     {
                         headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),

                      }
                     })
                  .then((response) => {
                         this.stocke_products_filter = response.data.data.data;
                       console.log('this.stocke_products_filter');
                         console.log(this.stocke_products_filter);

                        this.stocke_products =[] ;
                        Object.entries(this.stocke_products_filter).forEach(element => {
  
                          if(this.selected_category.name == element[1].category_name)
                          { 

                              this.stocke_products.push({'image':element[1].image, 'code':element[1].code,'qty':element[1].qty,'category_name':element[1].category_name});

                          }
       
	    	           });


                             console.log('this.stocke_products_length');
                          console.log(this.stocke_products_length);
 
                          if(this.stocke_products_length%100 != 0)
                           {
                              this.pageCount = parseInt(this.stocke_products_length/100) + 1;
                              console.log('this.pageCount');
                              console.log(this.pageCount);
                           }
                           else
                           {
                              this.pageCount = parseInt(this.stocke_products_length/100);
                              console.log('this.pageCount');
                              console.log(this.pageCount);
                           }
                       })
                       .catch((error) => {
                           console.log(error)
                        })
            },



        updateHandler(){
       
       this.get_products_qty(this.page);
       window.scrollTo(0, 0);
       },

        async get_products_qty(page) {

            axios.get("/qty-products/"+page,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),

                    }
                })
                .then((response) => {
                    this.stocke_products = response.data.data.data;
                    console.log('this.stocke_products');
                    console.log(this.stocke_products);

                    // this.stocke_products_length = Object.keys(this.stocke_products).length;
                    console.log('this.stocke_products_length');
                    console.log(this.stocke_products_length);
             
                    if(this.stocke_products_length%100 != 0)
                    {
                       this.pageCount = parseInt(this.stocke_products_length/100) + 1;
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }
                    else
                    {
                       this.pageCount = parseInt(this.stocke_products_length/100);
                       console.log('this.pageCount');
                       console.log(this.pageCount);
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

              // async get_products() {

        //     axios.get("products",
        //         {
        //             headers: {
        //                 Authorization: 'Bearer ' + localStorage.getItem('token'),

        //             }
        //         })
        //         .then((response) => {
        //             this.products = response.data.data;
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         })
        // },
    }
};

$(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});


$(document).ready(function () {
    $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
});



$(function () {
    $('#category').change(function () {
        var category = $('#category').val();
        $('tbody#myTable tr').each(function () {
            var productCategory = $(this).find('.product-category').text();
            if ((category == '' || category == productCategory)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    });
});


</script>

<style>
.table th {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
}

.paginate{
    text-align: center !important;
     width:70%;
    direction: ltr;
}

.paginate button{
    border:1px solid blue;
    padding: 20px;
}  

</style>
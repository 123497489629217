<template>
	<div class="app align-content-stretch d-flex flex-wrap">
		<div class="app-container">
			<div class="app-content">
				<div class="content-wrapper">
					<div class="container">
						<div class="row">
							<div class="col">
								<div class="page-description">
									<h1>  إضافة طقم </h1>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="ec-content-wrapper">
								<div class="content">
									<div class="row">
										<div class="col-12">
											<div class="card card-default">
												<div class="card-body">
													<div class="row ec-vendor-uploads">
														<div class="col-lg-12">
															<div class="ec-vendor-upload-detail">
																<form class="row g-3">

																	<div id="productsDiv">
																		<div class="row">
																			<div id="response1"></div>
																			<div class="col-md-3">

																				<label class="form-label"> الصنف
																				</label>
																				<select class="form-select  prod-inpu prdId"
																					v-model="selected_category"
																					v-on:change="getProductsOption()"
																					aria-label="Default select example">
																					<option v-for="category in categories"
																						v-bind:value="category">{{
																							category.name }}</option>

																				</select>

																			</div>

																			<div class="col-md-3">
																				<label class="form-label"> رمز المنتج
																				</label>
																				 <v-select class="product_id" v-model="selected_prd" name="product_id" :options="productsOptions" :value=id label=code></v-select>

																			</div>


																			<div class="col-md-2">
																				<label class="form-label"> الكمية </label>
																				<input v-model="prdQty" type="text"
																					class="form-control prdQty slug-title">
																			</div>

																		</div>
																		<div class="col-md-12 text-center">
																			<p> <br> يمكنك اضافة اكثر من منتج في نفس الوقت
																				وحفظهم مرة واحدة <br></p>
																			<div class="btn btn-primary button btn-lg btn-block but-full"
																				v-on:click="addProduct()">إضافة المزيد
																			</div>
																		</div>
																	</div>
																	
																	<div class="col-md-12">
																		<label class="form-label"> اسم الطقم </label>
																		<textarea v-model="name" class="form-control name"
																			rows="2"></textarea>
																	</div>

																	<div class="col-md-12">
																		<label class="form-label"> رمز الطقم </label>
																		<textarea v-model="package_code" class="form-control package_code"
																			rows="2"></textarea>
																	</div>


																	<section>
																		<table id="responsive-data-table" class="table"
																			style="width:100%">
																			<thead>
																				<tr>
																					<th> رمز المنتح </th>
																					<th> العدد </th>
																					<th> إجراء </th>
																				</tr>
																			</thead>

																			<tbody>
																				<tr v-for="(product, index ) in PackageProducts"
																					:key="product.id">
																					<td> {{ product.prdCode }} </td>
																					<td> {{ product.prdQty }} </td>
																					
																					<td>
																						<div class="btn-group mb-1">
																							
																							<button type="button"
																								class="btn btn-danger" v-on:click="deleteProduct(index)">
																								<span class="sr-only"> حذف
																								</span>
																							</button>
																						</div>
																					</td>
																				</tr>

																			</tbody>
																		</table>
																	</section>

																	<div class="col-md-12">
																		<div v-on:click="PostPackage()"
																			class="btn btn-success btn-block but-full"> حفظ
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> <!-- End Content -->
							</div> <!-- End Content Wrapper -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios';
import vSelect from "vue-select";

export default {
	name: 'add-packages',

	data() {
		return {
			products: [],
			prdCode: '',
			prdQty: 1,
			PackageProducts: [],
			packageId: '',
			product_id: '',
			productsOptions: [],
			productCode: [],
			categories: [],
			selected_category: [],
			selected_prd:[],
			name:'',
			package_code:'',

		}
	},
	components: {
	vSelect
  },
	mounted() {
		this.get_categories();
		this.getProducts();
	},

	methods: {

		get_categories() {

			axios.get("categories",
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.categories = response.data.data;
					// this.selected_category = this.categories[0];
					this.selected_category = '';
					this.selected_prd = '';
					this.getProductsOption();
				})
				.catch((error) => {
					console.log(error)
				})
		},

		getProducts() {
			this.PackageProducts = [];
			if (JSON.parse(localStorage.getItem("products")) !== "") {
				JSON.parse(localStorage.getItem("products")).forEach(element => {
					this.PackageProducts.push(element);
				});
			}
		},

		getProductsOption() {

			this.productsOptions = this.selected_category.products;
			console.log(this.selected_category);
		},

		addProduct() {
			
			this.products = JSON.parse(localStorage.getItem("products") || null);
			this.quantities = JSON.parse(localStorage.getItem("quantities") || null);
			
			this.product_id=this.selected_prd.id;

			axios.get("products/" + this.product_id,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.productCode = response.data.data.code;
					if (this.product_id != "" && this.prdQty != "") {

						this.PackageProducts.push(
							{
								prdId: this.product_id,
								prdQty: this.prdQty,
								prdCode: this.productCode,
							});
						let productsParsed = JSON.stringify(this.PackageProducts);
						localStorage.setItem('products', productsParsed);
						this.getProducts();
						this.get_categories();
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},

		PostPackage() {
			if (JSON.parse(localStorage.getItem("products")) !== "") {



				const formData = new FormData();

				formData.append('name', this.name);
				formData.append('package_code', this.package_code);

				axios.post('package', formData, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),

					}
				})
					.then((response) => {

						this.packageId = response.data.data.id;
						JSON.parse(localStorage.getItem("products")).forEach(element => {

							let prformData = new FormData();
							prformData.append('package_id', this.packageId);
							prformData.append('product_id', element.prdId);
							prformData.append('qty', element.prdQty);


							axios.post('package-products', prformData, {
								headers: {
									Authorization: 'Bearer ' + localStorage.getItem('token'),

								}
							})

						}
						)

						$('#response1').addClass("alert alert-success");
						document.getElementById("response1").innerHTML = "تمت إضافة الطقم بنجاح";
						document.getElementById("response1").style.background = "#4bad48";
						this.$router.push('/view-package');
						
					})
					.catch((error) => {
						console.log(error)

					})

			}

			else {

				let name = document.getElementsByClassName('name')[0].value;
				let package_code = document.getElementsByClassName('package_code')[0].value;

				const formData = new FormData();

				formData.append('name', name);
				formData.append('package_code', package_code);

				axios.post('packages', formData, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),

					}
				})
					.then((response) => {

						this.packageId = response.data.data.id;
						let prdId=this.selected_prd.id;
						let prdQty = document.getElementsByClassName('prdQty')[0].value;

						let prformData = new FormData();
						prformData.append('package_id', this.packageId);
						prformData.append('product_id', prdId);
						prformData.append('qty', prdQty);

						axios.post('package-products', prformData, {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('token'),

							}


						}
						)

						$('#response1').addClass("alert alert-success");

						document.getElementById("response1").innerHTML = "تمت إضافة الطقم بنجاح";
						this.$router.push('/view-package');
					
					})
					.catch((error) => {
						console.log(error)

					})

			}
		},


		deleteProduct: function (index) {

			this.PackageProducts.splice(index ,1);

			localStorage.setItem('products',JSON.stringify(this.PackageProducts));


		},


	},

	setup(){
			let products=JSON.stringify("");
		
		localStorage.setItem('products',products);	
		
		},


};


</script>

<style>
.form-label {
	font-size: 18px;
	font-weight: 600;
}
.vs__dropdown-toggle{
	margin-top: 1%;
	height: 40px;
}
@import "vue-select/dist/vue-select.css";

</style>